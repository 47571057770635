import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const TermsCondition = ({ auth }) => {
  return (
    <div className="container flex items-center">
      <div className="pb-16">
        <h1 className="my-8 max-sm:text-4xl">Terms and Conditions</h1>
        <h3 className="my-2">Last Updated: 5/30/2024</h3>
        <p className="py-2">
          These Terms and Conditions ("Terms", "Terms and Conditions") govern
          your relationship with{" "}
          <a
            href="https://tradeitfwd.com"
            className="!underline"
            target="_blank"
            rel="noreferrer"
          >
            tradeitfwd.com
          </a>{" "}
          website (the "Service") operated by{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> ("us",
          "we", or "our").
        </p>
        <p className="py-2">
          Please read these Terms and Conditions carefully before using our
          Service.
        </p>
        <p className="py-2">
          The <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> reserves
          the right to change or modify these Terms at any time and in its sole
          discretion. If The <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> makes
          changes to these Terms, we will provide you with notice of such
          changes, such as by sending an email, posting a notice on our Sites or
          updating the date at the top of these Terms. Your continued use of the
          Sites will confirm your acceptance of the revised Terms. We encourage
          you to frequently review the Terms to ensure you understand the terms
          and conditions that apply to your use of the Sites. If you do not
          agree to the amended Terms, you must stop using the Sites. Any use of
          the Sites in violation of these Terms may result in, among other
          things, termination of your account.
        </p>
        <h2 className="py-2 max-sm:text-2xl">1. Acceptance of Terms</h2>
        <p className="py-2">
          By accessing or using the Service, you agree to be bound by these
          Terms. If you disagree with any part of the terms, then you may not
          access the Service.
        </p>
        <h2 className="py-2 max-sm:text-2xl">2. Eligibility, Registration and Account</h2>
        <p className="py-2">
          The Service is not intended for participants under the age of 18. By
          using the service you represent and warrant that you are 18 years of
          age or older. If you are not at least 18 years of age, do not access,
          use or register for an account on the Sites. In addition, you may not
          make a purchase from our Sites unless you are at least 18 years of
          age. You also represent and warrant that you (a) have not previously
          been suspended or removed from the Sites; (b) do not have more than
          one Site account; and (c) that you have full power and authority to
          enter into this agreement and in doing so will not violate any other
          agreement to which you are a party.
        </p>
        <p className="py-2">
          In consideration of your use of the Service, you agree to (a) provide
          accurate, current and complete information; (b) maintain and promptly
          update your account information; (c) maintain the security of your
          password and accept all risks of unauthorized access to your account
          and the information you provide to{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>; and (d)
          promptly notify
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> if you
          discover or otherwise suspect any security breaches related to the{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>.
        </p>
        <h2 className="py-2 max-sm:text-2xl">3. Purchases</h2>
        <p className="py-2">
          If you wish to purchase any product or service made available through
          the Service ("Purchase"), you may be asked to supply certain
          information relevant to your Purchase including, without limitation,
          your name, email address, phone number, and shipping address.
        </p>
        <h2 className="py-2 max-sm:text-2xl">4. Subscriptions</h2>
        <p className="py-2">
          Some parts of the Service may be billed on a subscription basis
          ("Subscription(s)"). You will be billed in advance on a recurring and
          periodic basis ("Billing Cycle"). Billing cycles are set either on a
          monthly or annual basis, depending on the type of subscription plan
          you select when purchasing a Subscription.
        </p>
        <h2 className="py-2 max-sm:text-2xl">5. Free Trial</h2>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> may, at
          its sole discretion, offer a Subscription with a free trial for a
          limited period of time ("Free Trial"). You may be required to enter
          your billing information in order to sign up for the Free Trial.
        </p>
        <h2 className="py-2 max-sm:text-2xl">6. Fee Changes</h2>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>, in its
          sole discretion and at any time, may modify the Subscription fees for
          the Subscriptions. Any Subscription fee change will become effective
          at the end of the then-current Billing Cycle.
        </p>
        <h2 className="py-2 max-sm:text-2xl">7. Refunds</h2>
        <p className="py-2">
          Certain refund requests for Subscriptions may be considered by
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> on a
          case-by-case basis and granted at the sole discretion of{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>.
        </p>
        <h2 className="py-2 max-sm:text-2xl">8. Accounts Standing</h2>
        <p className="py-2">
          When you create an account with us, you must provide us with
          information that is accurate, complete, and current at all times.
          Failure to do so constitutes a breach of the Terms, which may result
          in immediate termination of your account on our Service.
        </p>
        <h2 className="py-2 max-sm:text-2xl">9. Intellectual Property</h2>
        <p className="py-2">
          The Service and its original content, features, and functionality are
          and will remain the exclusive property of{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> and its
          licensors.
        </p>
        <h2 className="py-2 max-sm:text-2xl">10. Links to Other Websites</h2>
        <p className="py-2">
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>.
        </p>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You
          further acknowledge and agree that{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> shall not
          be responsible or liable, directly or indirectly, for any damage or
          loss caused or alleged to be caused by or in connection with the use
          of or reliance on any such content, goods, or services available on or
          through any such web sites or services.
        </p>
        <h2 className="py-2 max-sm:text-2xl">11. Termination</h2>
        <p className="py-2">
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </p>
        <h2 className="py-2 max-sm:text-2xl">12. Limitation of Liability</h2>
        <p className="py-2">
          In no event shall <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>, nor its
          directors, employees, partners, agents, suppliers, or affiliates, be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including without limitation, loss of profits, data,
          use, goodwill, or other intangible losses, resulting from (i) your use
          or inability to use the Service; (ii) any unauthorized access to or
          use of our servers and/or any personal information stored therein;
          (iii) any interruption or cessation of transmission to or from the
          Service; (iv) any bugs, viruses, trojan horses, or the like that may
          be transmitted to or through our Service by any third party; (v) any
          errors or omissions in any content or for any loss or damage incurred
          as a result of your use of any content posted, emailed, transmitted,
          or otherwise made available through the Service; and/or (vi) the
          defamatory, offensive, or illegal conduct of any third party.
        </p>
        <h2 className="py-2 max-sm:text-2xl">13. Disclaimer</h2>
        <p className="py-2">
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>
        <h2 className="py-2 max-sm:text-2xl">14. Governing Law</h2>
        <p className="py-2">
          These Terms shall be governed and construed in accordance with the
          laws of the United States, without regard to its conflict of law
          provisions.
        </p>
        <h2 className="py-2 max-sm:text-2xl">15. Trademarks</h2>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> logos,
          names, product packaging, service names, slogans, and other
          identifiers that may appear on it’s websites and are trademarks or
          registered trademarks of <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> and its
          subsidiaries and may not be copied, imitated, or used, in whole or in
          part, without the prior written permission of{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>. All other
          trademarks, registered trademarks, product names and company names or
          logos mentioned in the Sites are the property of their respective
          owners. Reference to any products, services, processes or other
          information, by name, trademark, manufacturer, supplier or otherwise
          does not constitute or imply endorsement, sponsorship or
          recommendation by
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>.
        </p>
        <h2 className="py-2 max-sm:text-2xl">
          16. User Content and Interactive Features or Areas
        </h2>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>, in its
          sole discretion and at any time, may modify the Subscription fees for
          the Subscriptions. Any Subscription fee change will become effective
          at the end of the then-current Billing Cycle.
        </p>
        <h2 className="py-2 max-sm:text-2xl">17. Fee Changes</h2>
        <p className="py-2">
          The service may include discussion forums, blogs, profiles, product
          reviews or other interactive features or areas (collectively,
          "Interactive Areas"), in which you or other users create, post,
          transmit or store any content, such as text, music, sound, photos,
          video, graphics or code on the Sites ("User Content”). User Content is
          publicly-viewable and includes your profile information and any
          content you post pursuant to your profile, but it does not include
          your personal account information (also known as “Your Profile”) or
          information you submit in order to make a purchase. You agree that you
          are solely responsible for your User Content and for your use of such
          Interactive Areas, and that you use the Interactive Areas at your own
          risk.
        </p>
        <p className="py-2">
          By using any Interactive Areas, you agree not to post, upload to,
          transmit, distribute, store, create or otherwise publish or send
          through the Sites any of the following:
        </p>
        <ul>
          <li>
            User Content that is unlawful, libelous, defamatory, obscene,
            pornographic, indecent, lewd, suggestive, harassing, threatening,
            abusive, inflammatory, fraudulent or otherwise objectionable;
          </li>
          <li>
            User Content that would constitute, encourage or provide
            instructions for a criminal offense, violate the rights of any party
            or that would otherwise create liability or violate any local,
            state, national or international law;
          </li>
          <li>
            User Content that displays, describes or encourages usage of any
            product we sell in a manner that could be offensive, inappropriate
            or harmful to <span className="text-[#1cc400]">TRADE</span>
            <span>IT</span>
            <span className="text-[#1cc400]">FWD</span>
            <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> or any
            user or consumer or that is contrary to any instructions or warnings
            relating to the service (safety concerns can be reported here);
          </li>
          <li>
            User Content that may impinge upon or violate the publicity, privacy
            or data protection rights of others, including pictures or
            information about another individual where you have not obtained
            such individual's consent;
          </li>
          <li>
            User Content that makes false or misleading statements, claims or
            depictions about a person, company, product or service;
          </li>
          <li>
            User Content that does not clearly and prominently disclose any
            material connections you may have to{" "}
            <span className="text-[#1cc400]">TRADE</span>
            <span>IT</span>
            <span className="text-[#1cc400]">FWD</span>
            <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> or third
            party brands or sellers (for example, if you receive free products
            or services or are a paid blogger or employee);
          </li>
          <li>
            User Content that may infringe any patent, trademark, trade secret,
            copyright or other intellectual or proprietary right of any party;
          </li>
          <li>
            User Content that impersonates any person or entity or otherwise
            misrepresents your affiliation with a person or entity;
          </li>
          <li>
            Viruses, malware of any kind, corrupted data or other harmful,
            disruptive or destructive files or code; and
          </li>
          <li>
            User Content that, in the sole judgment of{" "}
            <span className="text-[#1cc400]">TRADE</span>
            <span>IT</span>
            <span className="text-[#1cc400]">FWD</span>
            <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>,
            restricts or inhibits any other person from using or enjoying the
            Sites or which may expose{" "}
            <span className="text-[#1cc400]">TRADE</span>
            <span>IT</span>
            <span className="text-[#1cc400]">FWD</span>
            <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> or its
            users to any harm or liability of any type.
          </li>
        </ul>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> takes no
          responsibility and assumes no liability for any User Content posted,
          stored or uploaded by you or any third party or for any loss or damage
          thereto, nor is <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> liable for
          any mistakes, defamation, slander, libel, omissions, falsehoods,
          obscenity, pornography or profanity you may encounter. At the same
          time, <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> encourages
          its users to report User Content that violates these Terms (by
          emailing{" "}
          <a href="mailto:support@tradeitfwd.com">support@tradeitfwd.com</a>).
          Except otherwise provided by law, enforcement of the Terms, however,
          is solely in our discretion and the absence of enforcement of these
          Terms in some instances does not constitute a waiver of our right to
          enforce the Terms in other instances. In addition, these Terms do not
          create any private right of action on the part of any third party or
          any reasonable expectation or promise that the Sites will not contain
          any content that is prohibited by such Terms. Although{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> has no
          obligation to screen, edit or monitor any of the User Content posted
          on the Sites, <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> reserves
          the right, and has absolute discretion, to remove, screen or edit any
          User Content posted or stored on the Sites at any time and for any
          reason without notice, and you are solely responsible for creating
          backup copies and replacing any User Content you post or store on the
          Sites at your sole cost and expense.
        </p>
        <p className="py-2">
          Any use of the Sites in violation of these Terms may result in, among
          other things, termination or suspension of your rights to use the
          Interactive Areas and/or the Service. In order to cooperate with
          legitimate governmental requests, subpoenas or court orders, to
          protect <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>'s systems
          and customers or to ensure the integrity and operation of{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>'s business
          and systems, <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> may access
          and disclose any information it considers necessary or appropriate,
          including, without limitation, user profile information, IP addressing
          and traffic information, usage history and posted User Content.{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>'s right to
          disclose any such information shall govern over any terms of{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>'s Privacy
          Policy.
        </p>
        <h2 className="py-2 max-sm:text-2xl">18. Severability</h2>
        <p className="py-2">
          If any provision of these Terms shall be deemed unlawful, void or for
          any reason unenforceable, then that provision shall be deemed
          severable from these Terms and shall not affect the validity and
          enforceability of any remaining provisions.
        </p>
        <h2 className="py-2 max-sm:text-2xl">19. Contact Us</h2>
        <p className="py-2">
          If you have any questions about these Terms, please contact us at:
          <a href="mailto:support@tradeitfwd.com">support@tradeitfwd.com</a>
        </p>
        {!auth.isAuthenticated && (
          <div className="flex justify-end pt-16">
            <Link
              to="/register"
              className="!bg-main !border-main first-letter:hover:text-white py-3 px-8 group border border-solid bg-transparent !text-white capitalize rounded-full transition-all duration-300 hover:!bg-second hover:!border-second"
            >
              Sign Up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(TermsCondition);
