import React, { useRef, useEffect, useState } from "react";
import classNames from "../../component/classNames";
import { serviceStore } from "../../../actions/serviceAction";
import { connect, useSelector } from "react-redux";
import { Button } from "@mui/material";
const ServiceRatePage = ({ serviceStore }) => {
  const service = useSelector((state) => state.service);
  const [isSelected, setIsSelected] = useState([false, false, false, false]);
  const [rate, setRate] = useState(service.serviceData["serviceRate"]);
  const [rateUs, setRateUs] = useState(service.serviceData["serviceRateUs"]);
  const curRef = useRef(null);

  useEffect(() => {
    let tmp = new Array(4).fill(false);
    tmp[service.serviceData["serviceRate"] / 5 - 1] = true;
    setIsSelected(tmp);
  }, [service.serviceData]);

  useEffect(() => {
    serviceStore({ num: 3, rateUs, flag: false, rate });
  }, [rate, rateUs, serviceStore]);

  const clickRateHandler = (i) => {
    setRate((i + 1) * 5);
    let tmp = new Array(4).fill(false);
    tmp[i] = true;
    setIsSelected(tmp);
  };

  return (
    <div>
      <p className="service-text-service leading-none">
        Set your rate in points
      </p>
      <p className="service-text leading-none my-6">
        This will be your compensation <br />
        that you use to trade for other services.
        <br />
        You can change this at any time.
      </p>
      <div className="flex gap-4 service-center">
        {[0, 1, 2, 3].map((i) => (
          <div
            key={`rate-${i}`}
            className="md:w-1/12 max-md:w-1/6 flex justify-center"
          >
            <Button
              type="button"
              className={classNames(
                "service-btn-rate-inactive !border !border-solid !border-main !rounded-full",
                isSelected[i] && "service-btn-rate-active !bg-main !text-white"
              )}
              onClick={() => clickRateHandler(i)}
            >
              {(i + 1) * 5}
            </Button>
          </div>
        ))}
      </div>
      <div className="form-group mt-6">
        <span className="service-text-label">
          Share more details on the service you will provide for this amount of
          points
        </span>
        <textarea
          className="form form-control mt-[5px] mb-16 border-none rounded-[13px]"
          placeholder="0/300"
          rows={5}
          defaultValue={rateUs}
          onChange={(e) => setRateUs(e.target.value)}
          ref={curRef}
        ></textarea>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  service: state.service,
});
export default connect(mapStateToProps, { serviceStore })(ServiceRatePage);
