import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Import action types
import { GET_ERRORS, GET_SUCCESS } from "../actions/types";

// Initial state of the service reducer
const initialState = {
  serviceData: {
    serviceName: "",
    serviceTellUs: "",
    serviceRate: 0,
    serviceRateUs: "",
    servicePhotoUrl: "",
    servicePresentation: "",
    serviceType: "virtual",
    servicePortfolios: [],
    serviceCertifications: [],
  },
};

// Service reducer function
const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    // If action type is GET_SUCCESS or GET_ERRORS
    case GET_SUCCESS:
    case GET_ERRORS:
      // Return new state with updated service data
      return {
        ...state,
        serviceData: action.payload,
      };
    // Default case
    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "tradetribe-",
  key: "auth",
  storage,
};

export default persistReducer(persistConfig, serviceReducer);
