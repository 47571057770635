import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import HomeIcon from "@material-ui/icons/Home";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import aws from "aws-sdk";
import api from "api";
import GoogleMapReact from "google-map-react";
import { usStates } from "util/usStates";
import ProvidingServices from "./components/ProvidingServices";
import ReceivingServices from "./components/ReceivingServices";
import { Link } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography></Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanels(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanels-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanels.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const defaultProps = {
  center: {
    lat: 37.409375796794684,
    lng: -122.08710396476175,
  },
  zoom: 10,
};

const Marker = () => {
  return <HomeIcon />;
};

const Dashboard = ({ socket }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const s3 = new aws.S3();

    api
      .get("/api/me")
      .then(async (res) => {
        if (isMounted) {
          let tmp = { ...res.data.data.user };
          if (tmp && tmp.media) {
            let params = { Bucket: "tradetribestore", Key: tmp.media.url };
            tmp.media.url = await s3.getSignedUrlPromise("getObject", params);
          }
          setUser(tmp);
        }
      })
      .catch((err) => {
        if (isMounted) console.error(err);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const getStateShortCode = (name) => {
    let val = usStates.find((state) => state.name === name.toUpperCase());
    return val ? val.abbreviation : name;
  };

  return (
    <main className="flex-1 dashboard">
      <div className="container">
        <div className="grid grid-cols-4 gap-8 max-md:grid-cols-1 w-full">
          <div className="side max-md:col-span-4 md:mr-[20px] md:min-w-[250px] max-md:mb-[25px] max-sm:gap-0">
            {user && (
              <Card
                sx={{ minWidth: 250 }}
                className="mb-4 text-center !bg-[#F9F9F9] !shadow-none !rounded-[20px] transition-shadow duration-300 hover:!drop-shadow-md"
              >
                <CardContent className="text-center pb-4 relative">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-center mt-6 mb-3"
                  >
                    <Link to={`/user-profile/${user.id}`}>
                      <Avatar
                        alt="Jessica"
                        src={user.media ? user.media.url : ""}
                        className="min-w-[90px] min-h-[90px]"
                      />
                    </Link>
                  </Stack>
                  <Typography
                    variant="body2"
                    className="mb-2 !text-[25px] !font-semibold"
                  >
                    {`Hi, ${user.firstName} ${user.lastName}`}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 13 }}
                    color="text.black"
                    gutterBottom
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: 13, color: "#1CC300", zIndex: 10 }}
                      className="mr-[5px]"
                    />
                    Member since: {new Date(user.createdAt).getFullYear()}
                  </Typography>
                </CardContent>
              </Card>
            )}
            <div className="pt-[28px] px-[19px] pb-[21px] mb-4 bg-[#f9f9f9] rounded-[20px]">
              <h3>Your Services</h3>
              <div className="mt-[19px]">
                {user &&
                  user.services.map((service, index) => (
                    <Button
                      size="small"
                      key={`service-${index}`}
                      className="py-1 px-3 !mr-[15px] !mb-[15px] !bg-[#F0F0F0] !rounded-full !text-[13px] !text-[#000] break-all"
                    >
                      {service.title}
                    </Button>
                  ))}
              </div>
            </div>
            {user && (
              <div className="pt-[28px] px-[19px] pb-[21px] mb-8 bg-[#f9f9f9] rounded-[20px]">
                <h3>Your Location</h3>
                {user.state && user.city && (
                  <p className="my-[12px] text-[15px]">
                    {user.city}, {getStateShortCode(user.state)}
                  </p>
                )}
                {user.lat && user.lng ? (
                  <div className="h-40">
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                        language: "en",
                      }}
                      center={{
                        lat: user.lat,
                        lng: user.lng,
                      }}
                      defaultZoom={defaultProps.zoom}
                      draggable={false}
                    >
                      <Marker lat={user.lat} lng={user.lng} />
                    </GoogleMapReact>
                  </div>
                ) : (
                  <p className="mt-4">You haven't added your address yet.</p>
                )}
              </div>
            )}
          </div>
          <div className="col-span-4 md:col-span-3 pb-[67px] md:max-lg:ml-[50px] md:max-lg:pl-[15px]">
            <ProvidingServices socket={socket} />

            <ReceivingServices socket={socket} />
          </div>
        </div>
      </div>
    </main>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps)(Dashboard);
