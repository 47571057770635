import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import aws from "aws-sdk";
import BasicCard from "../component/card";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axiosClient from "api";
import { toastify, toastType } from "util/toastify";

const Home = ({ auth }) => {
  const [popular, setPopular] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const s3 = new aws.S3();

    axiosClient
      .get("/public/get-popular")
      .then(async (res) => {
        let tmp = [...res.data.data.popular.rows];
        for (let i = 0; i < tmp.length; i++) {
          if (tmp[i].media) {
            let params = {
              Bucket: "tradetribestore",
              Key: tmp[i].media.url,
            };
            tmp[i].media.url = await s3.getSignedUrlPromise(
              "getObject",
              params
            );
          }
        }

        setPopular(tmp);
      }, [])
      .catch((err) => {
        console.error(err);
        toastify("Internal Server Error(s)", toastType.error);
      });
  }, []);

  return (
    <main className="flex-1">
      <div className="intro">
        <div className="items-center mb-20 mx-24 py-24 bg-[url('/src/assets/images/intro-1.jpg')] bg-center bg-cover bg-no-repeat rounded-[40px] max-lg:mb-10 max-lg:mx-10 max-sm:py-10 max-sm:mx-4 max-sm:mt-6 max-sm:mb-12">
          <div className="container justify-start">
            <div className="grid grid-cols-2 gap-2">
              <div className="p-20 bg-white/90 text-left rounded-[20px] max-2xl:p-12 max-lg:min-w-[385px] max-sm:min-w-[300px] max-sm:p-8">
                <h1 className="uppercase max-2xl:text-[30px] max-2xl:leading-normal max-sm:text-[24px]">
                  Give Something. <br />
                  Get Something. <br />
                  Pay Nothing.
                </h1>
                <p className="mt-6 mb-4">
                  Receive valuable services in exchange for sharing yours.
                </p>
                <div className="flex max-xl:block">
                  {!auth.isAuthenticated && (
                    <Link
                      to="/register"
                      className="inline-flex items-center !text-white mr-[14px] p-3 rounded-full bg-second border-second capitalize transition-all duration-300 hover:bg-[#156607] hover:border-[#156607] max-xl:mb-2 max-xl:block sm:max-xl:mb-4 max-sm:!py-2"
                    >
                      Join The Community
                      <FontAwesomeIcon
                        icon={faCircleArrowRight}
                        style={{ fontSize: 17, color: "white", zIndex: 10 }}
                        className="ml-2"
                      />
                    </Link>
                  )}
                  <div
                    to="/single-service"
                    onClick={() => {
                      localStorage.setItem("searchKeyword", "");
                      history.push("/single-service");
                    }}
                    className="inline-flex items-center text-white p-3 rounded-full bg-main border-main capitalize transition-all cursor-pointer duration-300 hover:bg-[#2cdd0e] hover:border-[#2cdd0e] max-sm:!py-2"
                  >
                    Search Services
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      style={{ fontSize: 17, color: "white", zIndex: 10 }}
                      className="ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="services w-full">
          <h2 className="mb-[42px]">Featured Services</h2>
          <Swiper
            modules={[Navigation]}
            navigation
            slidesPerView={3}
            spaceBetween={20}
            loop={true}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {popular.map((item, index) => (
              <SwiperSlide key={`popular-service-${index}`}>
                <BasicCard data={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="container my-20 max-sm:my-16">
        <div className="tribe mx-10 max-2xl:!mx-0">
          <div className="grid grid-cols-2 gap-6 max-xl:grid-cols-1">
            <div className="left text-left pr-6 my-auto">
              <h2 className="font-bold">
                What is{" "}
                <span className="text-second uppercase">
                  <span className="text-[#1cc400]">Trade</span>
                  <span>IT</span>
                  <span className="text-[#1cc400]">Fwd</span>
                  <sup
                    className="text-[#1cc400]"
                    style={{ fontSize: "16px", top: "-1em" }}
                  >
                    TM
                  </sup>
                </span>
              </h2>
              <p className="my-3">
                <span className="text-[#1cc400]">TRADE</span>
                <span>IT</span>
                <span className="text-[#1cc400]">FWD</span>
                <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> is a
                collective of like-minded individuals who recognize the value in
                creating a larger scale trade-based service economy, an economy
                that places human connection above economic status.{" "}
                <span className="text-[#1cc400]">TRADE</span>
                <span>IT</span>
                <span className="text-[#1cc400]">FWD</span>
                <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>{" "}
                takes the ancient practice of trading, fuses it with modern
                technology and takes it to a whole new level, allowing it to
                realize its full power and potential to positively impact the
                world. With <span className="text-[#1cc400]">TRADE</span>
                <span>IT</span>
                <span className="text-[#1cc400]">FWD</span>
                <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>,
                services are made available to all people, regardless of social
                or financial status.
              </p>
              {/* <p>We care about</p>
              <ul className="my-3">
                <li>
                  <span className="text-[#1cc400]">TRADE</span>
                  <span>IT</span>
                  <span className="text-[#1cc400]">FWD</span>
                  <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> Family
                </li>
                <li>Providing Great Services</li>
                <li>Making world a Better Place</li>
              </ul>
              <p>
                We belive trading services is the best way of making the world a
                better place.
              </p> */}
            </div>
            <div className="right max-xl:mt-6">
              <img
                src="/images/banner-1.jpg"
                alt="banner-1"
                width="665"
                height="404"
                className="xl:max-2xl:max-w-[600px] max-md:object-cover max-md:w-full max-md:rounded-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="workflow bg-[#0b4e0008] py-20 max-sm:py-16">
        <h2 className="font-bold text-center">
          How{" "}
          <span className="text-second uppercase">
            <span className="text-[#1cc400]">Trade</span>
            <span>IT</span>
            <span className="text-[#1cc400]">Fwd</span>
            <sup
              className="text-[#1cc400]"
              style={{ fontSize: "16px", top: "-1em" }}
            >
              TM
            </sup>
          </span>{" "}
          works
        </h2>
        <div className="container my-20 max-sm:my-10">
          <Swiper
            modules={[Navigation]}
            navigation
            slidesPerView={3}
            spaceBetween={20}
            loop={true}
            breakpoints={{
              360: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            <SwiperSlide>
              <div className="text-center static px-16">
                <figure className="p-6 rounded-full bg-main inline-flex">
                  <img
                    src="/images/Group.png"
                    alt="group"
                    width="39"
                    height="39"
                    className=" z-10"
                  />
                </figure>
                <h3 className="text-[22px] text-second font-medium mt-10 mb-2">
                  Join the Community
                </h3>
                <p>
                  Create a profile and offer up to three different services.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center static px-16">
                <figure className="p-6 rounded-full bg-main inline-flex">
                  <img
                    src="/images/wallet.png"
                    alt="wallet"
                    width="39"
                    height="39"
                    className="z-10"
                  />
                </figure>
                <h3 className="text-[22px] text-second font-medium mt-10 mb-2">
                  Earn Points
                </h3>
                <p>
                  Provide your services to other TIF members and receive points
                  from those members.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="text-center static px-16">
                <figure className="p-6 rounded-full bg-main inline-flex">
                  <img
                    src="/images/bag.png"
                    alt="bag"
                    width="39"
                    height="39"
                    className="z-10"
                  />
                </figure>
                <h3 className="text-[22px] text-second font-medium mt-10 mb-2">
                  Receive a Service
                </h3>
                <p>
                  Use the points you receive to enjoy any services being offered
                  by other TIF members.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {!auth.isAuthenticated && (
          <>
            <h2 className="text-center mb-10">Ready to Roll?</h2>
            <div className="flex justify-center">
              <Link
                to="/login"
                className="text-second hover:text-white py-3 px-8 group border border-solid !border-second bg-transparent capitalize rounded-full transition-all duration-300 hover:!bg-second"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="!text-white py-3 px-8 ml-4 border border-solid bg-second border-second capitalize rounded-full transition-all duration-300 hover:bg-main hover:border-main"
              >
                Join Now
              </Link>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Home);
