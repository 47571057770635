import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  SET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from "../actions/types";

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        notifications: action.payload,
      };
    case ADD_NOTIFICATION:
      let tmp = [...state.notifications];
      tmp.push(action.payload);
      return {
        notifications: tmp,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        notifications: [],
      };
    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "tradetribe-",
  key: "notifications",
  storage,
};

export default persistReducer(persistConfig, notificationReducer);
