export const getFileNameAndExtension = (fileName) => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex !== -1) {
    const name = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex + 1);
    return { name, extension };
  } else {
    // No extension found
    return { name: fileName, extension: "" };
  }
};
