import axiosClient from "api";
import React, { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Box, Checkbox, Tab, Tabs } from "@mui/material";

import PortfolioImageUpload from "../../component/portfolioImageUpload";
import CertificationImageUpload from "../../component/certificationImageUpload";
import { toastify, toastType } from "util/toastify";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ServiceDetailList({ user }) {
  const [services, setServices] = useState([]);
  const [activeServiceId, setActiveServiceId] = useState(0);
  const [, setActiveService] = useState(null);

  const [portfolioList, setPortfolioList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);

  useEffect(() => {
    user && setServices(user.services);
  }, [user]);

  useEffect(() => {
    user && axiosClient
      .get(`/api/service/${user.services[activeServiceId].UserService.id}`)
      .then(async (res) => {
        setActiveService(res.data.data.data);
        if (res.data.data.data.certification.length > 0) {
          setCertificationList(res.data.data.data.certification);
        } else {
          setCertificationList([]);
        }
        if (res.data.data.data.portfolio.length > 0) {
          setPortfolioList(res.data.data.data.portfolio);
        } else {
          setPortfolioList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        toastify("Internal Server Error(s)", toastType.error);
      });
  }, [user, activeServiceId]);

  const tabHandleChange = (event, newValue) => {
    setActiveServiceId(newValue);
  };

  const changeTitle = (value) => {
    let tmp = [...services];
    tmp[activeServiceId].title = value;
    setServices(tmp);
  };

  const changeType = (detail) => {
    let tmp = [...services];
    tmp[activeServiceId].type = detail;
    setServices(tmp);
  };

  const updateServiceDetail = () => {
    axiosClient
      .post("/api/users/update-detail", {
        serviceId: services[activeServiceId].id,
        userserviceId: services[activeServiceId].UserService.id,
        title: services[activeServiceId].title,
        experience: services[activeServiceId].UserService.experience,
        type: services[activeServiceId].type,
      })
      .then((_res) => {
        toastify("Service Detail is updated.", toastType.success);
      })
      .catch((err) => {
        console.error(err);
        toastify("Updating Service Detail is Failed.", toastType.error);
      });
  };

  const changeFieldRequirement = async (field, value) => {
    let tmp = [...services];
    if (field === "certification") {
      services[activeServiceId].UserService.requireCertifications = value;
    }
    if (field === "portfolio") {
      services[activeServiceId].UserService.requireWorkSamples = value;
    }
    await axiosClient.post("/api/service/update-requirement", {
      serviceId: services[activeServiceId].id,
      field,
      value,
    });
    setServices(tmp);
  };

  return (
    <div className="service-detail-list mt-2">
      <div className="sm:grid sm:grid-cols-4 sm:gap-4">
        <Box
          flexDirection={"column"}
        >
          <Tabs
            value={activeServiceId}
            onChange={tabHandleChange}
            aria-label="service detail tabs"
            className="service-nav max-sm:mb-2"
            indicatorColor="transparent"
          >
            {services.map((service, index) => (
              <Tab
                key={`service-title-${index}`}
                label={service.title}
                className="w-full max-sm:!max-w-full text-left mb-1"
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        <div className="col-span-3">
          <div className="edit-tab-content pb-10" sx={{ width: "100%" }}>
            {services.length > 0 ? (
              services.map((service, index) => (
                <TabPanel
                  key={`service-detail-${index}`}
                  value={activeServiceId}
                  index={index}
                >
                  <div className="edit-tab-panel">
                    <Stack>
                      <Item className="mb-6">
                        <h3 className="mt-[50px] mb-[26px] font-bold">
                          Service Title
                        </h3>
                        <input
                          type="text"
                          name="service_title"
                          placeholder="Enter here"
                          defaultValue={service.title}
                          //   value={service.title}
                          onBlur={(e) => changeTitle(e.target.value)}
                          className="w-full h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                          required
                        />
                        <h3 className="mt-[30px] mb-[4px] font-bold">
                          Service Description and Your Experience
                        </h3>
                        <p className="mt-[7px] mb-[19px] text-[#a1a1a1]">
                          Tell us about your working experience
                        </p>
                        <textarea
                          type="text"
                          name="field"
                          placeholder="Enter here"
                          defaultValue={service.UserService.experience}
                          //   value={service.UserService.experience}
                          className="w-full max-w-[915px] h-[178px] mb-4 p-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                          required
                        />
                        <h3 className="mt-[30px] mb-2 font-bold">
                          Select your service type.
                        </h3>
                        <div className="ml-2 mb-8 flex items-center text-lg">
                          <input
                            type="radio"
                            name="type"
                            className="mr-1"
                            value="virtual"
                            onClick={(e) => changeType(e.target.value)}
                            checked={service.type === "virtual"}
                            key={`virtual-${service.id}`}
                          />
                          <span className="mr-8">Virtual</span>
                          <input
                            type="radio"
                            name="type"
                            className="mr-1"
                            value="in-person"
                            onClick={(e) => changeType(e.target.value)}
                            checked={service.type === "in-person"}
                            key={`in-person-${service.id}`}
                          />
                          <span className="mr-8">In Person</span>
                          <input
                            type="radio"
                            name="type"
                            className="mr-1"
                            value="both"
                            onClick={(e) => changeType(e.target.value)}
                            checked={service.type === "both"}
                            key={`both-${service.id}`}
                          />
                          <span>Both</span>
                        </div>
                        <div className="flex justify-between items-center max-w-[511px]">
                          <button
                            type="button"
                            onClick={updateServiceDetail}
                            className="block py-2 px-16  text-white bg-main border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#2bef0a] focus:outline-none active:outline-none"
                          >
                            Save
                          </button>
                        </div>
                      </Item>
                      <Item id="portfolio-list" className="text-left">
                        <div className="flex">
                          <div className="basis-1/2">
                            <span>
                              <Checkbox
                                className="mr-1"
                                // defaultChecked={
                                //   service.UserService.requireWorkSamples
                                // }
                                checked={service.UserService.requireWorkSamples}
                                onChange={(e) =>
                                  changeFieldRequirement(
                                    "portfolio",
                                    e.target.checked
                                  )
                                }
                              />
                              Add Work Sample
                            </span>
                          </div>
                          <div className="basis-1/2">
                            <span>
                              <Checkbox
                                className="mr-1"
                                // defaultChecked={
                                //   service.UserService.requireCertifications
                                // }
                                checked={
                                  service.UserService.requireCertifications
                                }
                                onChange={(e) =>
                                  changeFieldRequirement(
                                    "certification",
                                    e.target.checked
                                  )
                                }
                              />
                              Add Certifications
                            </span>
                          </div>
                        </div>
                        {service.UserService.requireWorkSamples ? (
                          <>
                            <h3 className="mt-[40px] mb-[6px] font-bold text-center">
                              Work Samples
                            </h3>
                            <p className="mt-[7px] mb-[19px] text-[#a1a1a1] text-center">
                              Update your work samples
                            </p>
                            <PortfolioImageUpload
                              items={portfolioList}
                              setter={setPortfolioList}
                              serviceId={service.id}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Item>
                      <Item id="certification-list" className="text-left">
                        {service.UserService.requireCertifications ? (
                          <>
                            <h3 className="mt-[60px] mb-[6px] font-bold text-center">
                              Professional Certifications
                            </h3>
                            <p className="mt-7px] mb-[19px] text-[#a1a1a1] text-center">
                              Update your Licenses, certifications, etc
                            </p>
                            <CertificationImageUpload
                              items={certificationList}
                              setter={setCertificationList}
                              serviceId={service.id}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Item>
                    </Stack>
                  </div>
                </TabPanel>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
