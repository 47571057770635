import React from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "ZIP", "RAR"];

function FileUpload({ files, setFiles }) {
  const handleChange = (file) => {
    setFiles([...files, file]);
  };
  return (
    <div>
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      {files.length > 0 && (
        <>
          {files.map((file, index) => (
            <div className="flex items-center" key={`complete-file-${index}`}>
              {file.name} ({file.size} bytes)
              <button
                className="border-px rounded-full w-5 h-5 ml-2 text-xs p-0 bg-transparent leading-4 border-gray-400 hover:bg-gray-500 hover:text-black-500"
                onClick={(e) => {
                  e.preventDefault();
                  setFiles(files.filter((f) => f !== file));
                }}
              >
                x
              </button>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default FileUpload;
