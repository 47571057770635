import {
  SET_KEYWORD,
  SET_RADIUS,
  SET_TYPE,
  SET_LOCATION,
} from "../actions/types";

const initialState = {
  keyword: "",
  radius: 30000,
  type: "both",
  location: "global",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case SET_RADIUS:
      return {
        ...state,
        radius: action.payload,
      };
    case SET_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
