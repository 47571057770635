// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1CC400", // Replace this with your desired primary color
    },
    warning: {
      main: "#FFEE00",
    },
  },
});

export default theme;
