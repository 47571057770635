import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

import axiosClient from "api/index.js";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [sentState, setSentState] = useState(false);

  const history = useHistory();

  const onSubmit = async (data) => {
    setSentState(true);
    console.log(data)

    axiosClient
      .post("/public/send-contact-us-msg", data)
      .then((res) => {
        if (res.statusText === "OK") {
          toast.success("Message successfully sent. Someone will contact you shortly.", {
            position: "top-center",
            containerId: "main",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          history.push("/");
        } else {
          toast.error("Failed to sent message, please try it again.", {
            position: "top-center",
            containerId: "main",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        setSentState(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to sent message, please try it again.", {
          position: "top-center",
          containerId: "main",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSentState(false);
      });
  };

  return (
    <div className="container flex items-center">
      <div className="my-20 max-md:my-12 w-full">
        <h1 className="mb-12 max-md:text-5xl">Contact Us</h1>

        <form
          className="text-lg w-full"
          id="contact-form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="flex mb-4">
            <label className="leading-[48px] mb-0 text-lg mr-2 min-w-[80px] text-end">Name</label>
            <input
              name="name"
              type="text"
              placeholder="Name"
              {...register("name", {
                required: { value: true, message: "Please enter your name" },
                maxLength: {
                  value: 30,
                  message: "Please use 30 characters or less",
                },
              })}
              className="w-full min-w-[240px] max-w-[600px] p-2 rounded !outline-none"
            />

            {errors.name && (
              <span className="errorMessage">{errors.name.message}</span>
            )}
          </div>
          <div className="flex mb-4">
            <label className="leading-[48px] mb-0 text-lg mr-2 min-w-[80px] text-end">Email</label>
            <input
              name="email"
              type="email"
              placeholder="Email Address"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              className="w-full min-w-[240px] max-w-[600px] p-2 rounded !outline-none"
            />

            {errors.email && (
              <span className="errorMessage">
                Please enter a valid email address
              </span>
            )}
          </div>
          <div className="flex mb-4">
            <label className="leading-[48px] mb-0 text-lg mr-2 min-w-[80px] text-end">
              Subject
            </label>
            <input
              name="subject"
              type="text"
              placeholder="Subject"
              {...register("subject", {
                required: { value: true, message: "Please enter a subject" },
                maxLength: {
                  value: 75,
                  message: "Subject cannot exceed 75 characters",
                },
              })}
              className="w-full min-w-[240px] max-w-[600px] p-2 rounded !outline-none"
            />
            {errors.subject && (
              <span className="errorMessage">{errors.subject.message}</span>
            )}
          </div>
          <div className="flex mb-4">
            <label className="leading-[48px] mb-0 text-lg mr-2 min-w-[80px] text-end">
              Message
            </label>
            <textarea
              rows={3}
              name="message"
              type="text"
              placeholder="Message"
              {...register("message", {
                required: true,
              })}
              className="w-full min-w-[240px] max-w-[600px] p-2 rounded !outline-none"
            />
            {errors.message && (
              <span className="errorMessage">Please enter a message</span>
            )}
          </div>
          <div>
            <button
              type="submit"
              className={
                sentState
                  ? "px-4 py-2 !outline-none border"
                  : "px-4 py-2 !outline-none border hover:bg-black hover:text-white transition-all duration-300"
              }
              disabled={sentState}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
