import React from "react";

const Helps = () => {
  return (
    <div className="container flex">
      <div className="px-20 max-lg:px-10 max-sm:px-4 my-20">
        <a href="mailto:support@tradeitfwd.com">
          <h1 className="mb-8 max-lg:text-5xl max-md:text-4xl max-sm:text-3xl">support@tradeitfwd.com</h1>
        </a>
        <h2 className="max-sm:text-xl max-md:text-2xl max-lg:text-3xl">
          We are committed to responding to all inquiries in less than 24 hours.
        </h2>
      </div>
    </div>
  );
};

export default Helps;
