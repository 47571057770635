import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toastify, toastType } from "util/toastify";

import api from "../../api";

const AddService = () => {
  const [service, setService] = useState({});

  const rateRef = useRef(null);

  const history = useHistory();

  const changeRate = (rate) => {
    let tmp = service;
    tmp.rate = rate;
    rateRef.current.value = rate;
    setService(tmp);
  };

  const changeTitle = (title) => {
    let tmp = service;
    tmp.title = title;
    setService(tmp);
  };

  const changeDesc = (detail) => {
    let tmp = service;
    tmp.detail = detail;
    setService(tmp);
  };

  const changeExp = (detail) => {
    let tmp = service;
    tmp.experience = detail;
    setService(tmp);
  };

  const changeType = (detail) => {
    let tmp = service;
    tmp.type = detail;
    setService(tmp);
  };

  const saveHandler = () => {
    if (service.title && service.title !== "") {
      if (service.rate && service.rate !== 0) {
        api
          .post(`/api/service/new/`, service)
          .then(() => {
            toastify("New Service Added.", toastType.success);
            history.push("/my-profile");
          })
          .catch((err) => {
            console.error(err);
            toastify("Adding New Service Failed.", toastType.error);
          });
      } else {
        toastify("Input Correct Rate For Service.", toastType.error);
      }
    } else {
      toastify("Please Input Service Title.", toastType.error);
    }
  };

  return (
    <main className="flex-1 edit-service mb-16">
      <div className="container justify-center">
        <div className="edit-panel w-full max-w-[800px] bg-[#f9f9f9] py-10 px-5 sm:px-10 md:px-20 rounded-[20px]">
          <h2 className="text-center">Your Service</h2>
          <p className="text-center mt-[24px] mb-[52px]">
            Please enter your details for the services
          </p>
          <form>
            <h3 className="ml-2 mb-2">Service Title</h3>
            <input
              type="text"
              name="service_title"
              placeholder="Enter here"
              onChange={(e) => changeTitle(e.target.value)}
              className="w-full h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
              required
            />
            <h3 className="ml-2 mb-2">What Experience You Have</h3>
            <textarea
              type="text"
              name="field"
              placeholder="Enter here"
              onChange={(e) => changeExp(e.target.value)}
              className="w-full h-36 mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
              required
            />
            <h3 className="ml-2 mb-2">What Will Receiver Get</h3>
            <textarea
              type="text"
              name="field"
              placeholder="Enter here"
              onChange={(e) => changeDesc(e.target.value)}
              className="w-full h-36 mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
              required
            />
            <h3 className="ml-2 mb-2">Select your service type.</h3>
            <div className="ml-2 mb-4 flex items-center text-lg">
              <input
                type="radio"
                name="type"
                className="mr-1"
                value="virtual"
                onClick={(e) => changeType(e.target.value)}
                checked={service.type }
              />
              <span className="mr-8">Virtual</span>
              <input
                type="radio"
                name="type"
                className="mr-1"
                value="in-person"
                onClick={(e) => changeType(e.target.value)}
              />
              <span className="mr-8">In Person</span>
              <input
                type="radio"
                name="type"
                className="mr-1"
                value="both"
                onClick={(e) => changeType(e.target.value)}
              />
              <span>Both</span>
            </div>
            <h3 className="ml-2 mb-2">Points Rate for Service</h3>
            <div className="relative">
              <input
                type="number"
                name="points_rate"
                min={0}
                ref={rateRef}
                onChange={(e) => changeRate(e.target.value)}
                defaultValue={0}
                placeholder="Enter here"
                className="w-full h-12 mb-4 text-base pl-4 border border-solid border-[#e9e9e9] outline-none rounded-xl"
                required
              />
              <div className="flex absolute top-1.5 right-2">
                <button
                  type="button"
                  onClick={() => changeRate(5)}
                  className="px-4 sm:px-5 py-2 mr-2.5 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                >
                  5
                </button>
                <button
                  type="button"
                  onClick={() => changeRate(10)}
                  className="px-4 sm:px-5 py-2 mr-2.5 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                >
                  10
                </button>
                <button
                  type="button"
                  onClick={() => changeRate(15)}
                  className="px-4 sm:px-5 py-2 mr-2.5 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                >
                  15
                </button>
                <button
                  type="button"
                  onClick={() => changeRate(20)}
                  className="px-4 sm:px-5 py-2 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                >
                  20
                </button>
              </div>
            </div>
            <button
              type="button"
              onClick={() => saveHandler()}
              className="block mx-auto py-2 md:py-3 px-8 md:px-16 mt-[25px] text-white bg-main border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#2bef0a]"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default AddService;
