// Import action types
import {
  SET_CURRENT_FAVORITES,
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  CLEAR_FAVORITES,
} from "./types";

// Set current favorites in the store
export const setCurrentFavorites = (favorites) => (dispatch) => {
    try {
      console.log("setCurrentFavorites", favorites);
      dispatch({
        type: SET_CURRENT_FAVORITES,
        payload: favorites,
      });
    } catch (err) {
      console.error(err);
    }
};

// Add an item to the favorites list
export const addToFavorites = (id) => (dispatch) => {
  try {
    dispatch({
      type: ADD_TO_FAVORITES,
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

// Remove an item from the favorites list
export const removeFromFavorites = (id) => (dispatch) => {
  try {
    dispatch({
      type: REMOVE_FROM_FAVORITES,
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

// Clear all items from the favorites list
export const clearFavorites = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_FAVORITES,
    });
  } catch (err) {
    console.error(err);
  }
};