import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import PointsTable from '../../view/component/pointsTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanels(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanels-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function a12yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Credits() {

	const [value, setValue] =  useState(0);
	const [values, setValues] = useState(0);

	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};

	const handleChanges = (event, newValue) => {
	  setValues(newValue);
	};
    
    return (
        <main className="flex-1 credits mt-[30px] mb-[100px]">
            <div className="container mb-[65px]">
                <div className="grid grid-cols-3 gap-3 w-full text-center pt-12 pb-16 border-none bg-[#f9f9f9] rounded-[20px] max-md:grid-cols-1 max-md:gap-0 max-md:pt-10 max-md:pb-10">
                    <div className="border-t-0 border-l-0 border-b-0 border-r border-solid border-r-[#ccc] max-md:border-r-0 max-md:mb-6">
                        <p>Available Points</p>
                        <p className="font-semibold text-5xl">126</p>
                    </div>
                    <div className="border-t-0 border-l-0 border-b-0 border-r border-solid border-r-[#ccc] max-md:border-r-0 max-md:mb-6">
                        <p>Points Received</p>
                        <p className="font-semibold text-5xl">126</p>
                    </div>
                    <div className="">
                        <p>Points Given</p>
                        <p className="font-semibold text-5xl">126</p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="flex flex-1 justify-between">
                    <h2>Points</h2>
                    <div className="flex">
                        <div>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="main"
                                aria-label="my tab1"
                                className="text-[#000]"
                            >
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Added" {...a11yProps(1)} />
                                <Tab label="Received" {...a11yProps(2)} />
                                <Tab label="Given" {...a11yProps(3)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>

                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                
                            </TabPanel>
                        </div>
                        <div>
                            <Tabs
                                value={values}
                                onChange={handleChanges}
                                indicatorColor="main"
                                aria-label="my tab2"
                                className="text-[#000]"
                            >
                                <Tab label="Last Week" {...a12yProps(0)} />
                                <Tab label="Last Month" {...a12yProps(1)} />
                                <Tab label="All Time" {...a12yProps(2)} />
                            </Tabs>
                            <TabPanels value={value} index={0}>

                            </TabPanels>
                            <TabPanels value={value} index={1}>
                                
                            </TabPanels>
                            <TabPanels value={value} index={2}>
                                
                            </TabPanels>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <PointsTable />
            </div>
        </main>
    );
}