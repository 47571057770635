import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toastify, toastType } from "util/toastify";

import api from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function EditService() {
  const location = useLocation();
  const [serviceId] = useState(
    new URLSearchParams(location.search).get("serviceId")
  );
  const [service, setService] = useState(null);

  const rateRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    api
      .get(`/api/service/${serviceId}`)
      .then((res) => {
        setService(res.data.data.data);
      })
      .catch((err) => {
        console.error(err);
        toastify("Internal Server Error(s)", toastType.success);
      });
  }, [serviceId]);

  const changeRate = (rate) => {
    let tmp = {...service};
    tmp.rate = rate;
    rateRef.current.value = rate;
    setService(tmp);
  };

  const changeTitle = (title) => {
    let tmp = {...service};
    tmp.service.title = title;
    setService(tmp);
  };

  const changeDesc = (detail) => {
    let tmp = {...service};
    tmp.detail = detail;
    setService(tmp);
  };

  const changeExp = (experience) => {
    let tmp = {...service};
    tmp.experience = experience;
    setService(tmp);
  };

  const changeType = (detail) => {
    let tmp = {...service};
    tmp.service.type = detail;
    setService(tmp);
  };

  const saveHandler = () => {
    api
      .post(`/api/service/${serviceId}`, service)
      .then((res) => {
        history.push("/my-profile");
        toastify(
          "Your Service Profile Updated Successfully.",
          toastType.success
        );
      })
      .catch((err) => {
        console.error(err);
        toastify("Your Service Profile Updated Failed.", toastType.success);
      });
  };

  const deleteHandler = () => {
    api
      .delete(`/api/service/${serviceId}`)
      .then((res) => {
        history.push("/my-profile");
        toastify("Your Service deleted Successfully.", toastType.success);
      })
      .catch((err) => {
        console.error(err);
        toastify("Failed To Delete Your Service.", toastType.error);
      });
  };

  return (
    <main className="flex-1 edit-service mb-16">
      <div className="container justify-center">
        <div className="edit-panel relative w-full max-w-[800px] bg-[#f9f9f9] py-10 px-5 sm:px-10 md:px-20 rounded-[20px]">
          <h2 className="text-center">Your Service</h2>
          <p className="text-center mt-[24px] mb-[52px]">
            Please enter your details for the services
          </p>
          {service && service.service && (
            <form>
              <h3 className="ml-2 mb-2">Service Title</h3>
              <input
                type="text"
                name="service_title"
                placeholder="Enter here"
                defaultValue={
                  service.service &&
                  service.service.title &&
                  service.service.title
                }
                onChange={(e) => changeTitle(e.target.value)}
                className="w-full h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                required
              />
              <h3 className="ml-2 mb-2">What Experience You Have</h3>
              <textarea
                type="text"
                name="field"
                placeholder="Enter here"
                defaultValue={service.experience && service.experience}
                onChange={(e) => changeExp(e.target.value)}
                className="w-full h-36 mb-4 p-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                required
              />
              <h3 className="ml-2 mb-2">What Will Receiver Get</h3>
              <textarea
                type="text"
                name="field"
                placeholder="Enter here"
                defaultValue={service.detail && service.detail}
                onChange={(e) => changeDesc(e.target.value)}
                className="w-full h-36 mb-4 p-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                required
              />
              <h3 className="ml-2 mb-2">Points Rate for Service</h3>
              <div className="relative">
                <input
                  type="number"
                  name="points_rate"
                  min={0}
                  ref={rateRef}
                  onChange={(e) => changeRate(e.target.value)}
                  defaultValue={service.rate}
                  placeholder="Enter here"
                  className="w-full h-12 mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <div className="flex absolute top-1.5 right-2">
                  <button
                    type="button"
                    onClick={(e) => changeRate(5)}
                    className="px-4 sm:px-5 py-2 mr-2.5 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                  >
                    5
                  </button>
                  <button
                    type="button"
                    onClick={(e) => changeRate(10)}
                    className="px-4 sm:px-5 py-2 mr-2.5 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                  >
                    10
                  </button>
                  <button
                    type="button"
                    onClick={(e) => changeRate(15)}
                    className="px-4 sm:px-5 py-2 mr-2.5 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                  >
                    15
                  </button>
                  <button
                    type="button"
                    onClick={(e) => changeRate(20)}
                    className="px-4 sm:px-5 py-2 border border-solid !border-[#1CC300] bg-transparent rounded-full "
                  >
                    20
                  </button>
                </div>
              </div>

              <h3 className="ml-2 mb-2">Select your service type.</h3>
              <div className="ml-2 mb-4 flex items-center text-lg">
                <input
                  type="radio"
                  name="type"
                  className="mr-1"
                  value="virtual"
                  onClick={(e) => changeType(e.target.value)}
                  checked={service.service.type === "virtual"}
                />
                <span className="mr-8">Virtual</span>
                <input
                  type="radio"
                  name="type"
                  className="mr-1"
                  value="in-person"
                  onClick={(e) => changeType(e.target.value)}
                  checked={service.service.type === "in-person"}
                />
                <span className="mr-8">In Person</span>
                <input
                  type="radio"
                  name="type"
                  className="mr-1"
                  value="both"
                  onClick={(e) => changeType(e.target.value)}
                  checked={service.service.type === "both"}
                />
                <span>Both</span>
              </div>

              <div className="flex justify-center">
                <Link
                  to="/my-profile"
                  className="block mx-2 py-2 md:py-3 px-8 md:px-16 mt-[25px] text-white bg-[#BE123C] border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#8C0E2D]"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  onClick={(e) => saveHandler()}
                  className="block mx-2 py-2 md:py-3 px-8 md:px-16 mt-[25px] text-white bg-main border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#2bef0a]"
                >
                  Save
                </button>
              </div>
            </form>
          )}
          <button
            onClick={deleteHandler}
            className="group absolute top-10 right-10 border-none bg-transparent transition-all duration-300"
          >
            <FontAwesomeIcon
              className="group-hover:!text-[#BE123C]"
              icon={faTrashAlt}
              style={{ fontSize: 23, color: "#000" }}
            />
          </button>
        </div>
      </div>
    </main>
  );
}
