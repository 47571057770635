import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Import action types
import {
  GET_SUCCESS,
  GET_ERRORS,
  SET_AVATAR,
  SET_USER,
  SET_ERROR,
} from "../actions/types";

// Import isEmpty utility function
const isEmpty = require("is-empty");

// Initial state of the reducer
const initialState = {
  isAuthenticated: false,
  user: {},
};

// Reducer function
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle GET_SUCCESS action
    case GET_SUCCESS:
      return {
        ...state, // spread operator to copy existing state
        flag: false,
        serviceData: action.payload,
      };
    // Handle GET_ERRORS action
    case GET_ERRORS:
      return {
        ...state, // spread operator to copy existing state
        flag: false,
      };
    // Handle SET_AVATAR and SET_USER action
    case SET_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload.avatar,
        },
      };
    case SET_USER:
      return {
        ...state,
        // isAuthenticated: !isEmpty(action.payload.decoded_data), // check if decoded data is empty
        isAuthenticated:
          localStorage.jwtToken && !isEmpty(action.payload.decoded_data), // check if decoded data is empty
        user: action.payload.decoded_data,
      };
    // Handle SET_ERROR action
    case SET_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
        msg: "Incorrect email or password", // set error message
      };
    // Default case
    default:
      return state;
  }
};

const persistConfig = {
  keyPrefix: "tradetribe-",
  key: "auth",
  storage,
};

export default persistReducer(persistConfig, authReducer);
