import React, { useState } from "react";
import { serviceStore } from "../../../actions/serviceAction";
import { connect, useSelector } from "react-redux";
const ServiceUsPage = ({ serviceStore }) => {
  const service = useSelector((state) => state.service);
  const [serviceTellUs, setServiceTellUs] = useState(
    service.serviceData["serviceTellUs"]
  );
  const [serviceType, setServiceType] = useState(
    service.serviceData["serviceType"]
  );
  const setValue = (e) => {
    setServiceTellUs(e.target.value);
    serviceStore({
      num: 2,
      tellUs: e.target.value,
      type: serviceType,
      flag: false,
    });
  };
  const setValue2 = (e) => {
    setServiceType(e.target.value);
    serviceStore({
      num: 2,
      tellUs: serviceTellUs,
      type: e.target.value,
      flag: false,
    });
  };
  return (
    <div>
      <p className="service-text-service">Tell us more</p>
      <div className="form-group mt-3">
        <span className="service-text-label">
          Please share more details about your service and experience.
        </span>
        <textarea
          className="form form-control mt-[5px] mb-2 border-none rounded-[13px]"
          placeholder="Enter here"
          rows={5}
          onChange={(e) => setValue(e)}
          defaultValue={serviceTellUs}
        ></textarea>
      </div>
      <div className="form-group mb-16 flex items-center text-lg">
        <input
          type="radio"
          name="service_type"
          className="mr-1"
          value="virtual"
          onClick={setValue2}
          checked={serviceType === "virtual"}
        />
        <span className="mr-8">Virtual</span>
        <input
          type="radio"
          name="service_type"
          className="mr-1"
          value="in-person"
          onClick={setValue2}
          checked={serviceType === "in-person"}
        />
        <span className="mr-8">In Person</span>
        <input
          type="radio"
          name="service_type"
          className="mr-1"
          value="both"
          onClick={setValue2}
          checked={serviceType === "both"}
        />
        <span>Both</span>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  service: state.service,
});
export default connect(mapStateToProps, { serviceStore })(ServiceUsPage);
