import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import aws from "aws-sdk";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import axiosClient from "api";
import TDChatBody from "./components/TDChatBody";
import TDChatFooter from "./components/TDChatFooter";
import FileUpload from "../component/fileUpload";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import slugify from "react-slugify";

import { myBucket } from "../../util/s3";
import { toastify, toastType } from "util/toastify";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  textAlign: "center",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  paddingTop: "40px",
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingBottom: "40px",
  borderRadius: "20px",
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // backgroundColor: "#1CC400",
    // color: "#1CC400",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    display: "block",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    marginBottom: "80px",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    display: "flex",
    flexDirection: "column",
    height: "350px",
    overflowY: "auto",
  },
});

const Task = ({ auth, socket }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);

  const location = useLocation();

  const [taskId] = useState(new URLSearchParams(location.search).get("taskId"));
  const [taskInfo, setTaskInfo] = useState(null);
  const [providerRate, setProviderRate] = useState(0);
  const [receiverRate, setReceiverRate] = useState(0);
  const [providerAvatarLink, setProviderAvatarLink] = useState("");
  const [receiverAvatarLink, setReceiverAvatarLink] = useState("");
  const [messages, setMessages] = useState([]);
  const [taskStatus, setTaskStatus] = useState(null);
  const [files, setFiles] = useState([]);
  const [sending, setSending] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const s3 = new aws.S3();
    if (!isMounted) {
      axiosClient
        .post("api/task/get-task-info", {
          id: taskId,
        })
        .then(async (res) => {
          let tmp = res.data.data.taskInfo;

          console.log(tmp);
          if (tmp.provider.media) {
            let providerParams = {
              Bucket: "tradetribestore",
              Key: tmp.provider.media.url,
            };
            let providerPromise = s3.getSignedUrlPromise(
              "getObject",
              providerParams
            );
            providerPromise.then(
              (url) => {
                console.log(url);
                setProviderAvatarLink(url);
              },
              (err) => {
                console.error(err);
              }
            );
          }
          if (tmp.receiver.media) {
            let receiverParams = {
              Bucket: "tradetribestore",
              Key: tmp.receiver.media.url,
            };
            let receiverPromise = s3.getSignedUrlPromise(
              "getObject",
              receiverParams
            );
            receiverPromise.then(
              (url) => {
                console.log(url);
                setReceiverAvatarLink(url);
              },
              (err) => {
                console.error(err);
              }
            );
          }

          setTaskInfo(res.data.data.taskInfo);
          setProviderRate(res.data.data.rate);
          setReceiverRate(res.data.data.rate1);
          setMessages(
            res.data.data.taskInfo.chatroom.messages.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );

          setIsMounted(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      if (taskInfo) {
        socket.emit("TDChatDisconnect", {
          roomId: taskInfo.chatroom.id,
        });
      }
    };
  }, [isMounted, socket, taskId, taskInfo]);

  useEffect(() => {
    const handler1 = (data) => {
      toast.warning(data, {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };

    const handler2 = (data) => setTaskStatus(data);

    socket && socket.on("InvalidAction", handler1);

    socket && socket.on("UpdateTaskStatus", handler2);

    return () => {
      socket.off("InvalidAction", handler1);

      socket.off("UpdateTaskStatus", handler2);
    };
  }, [socket]);

  useEffect(() => {
    taskInfo &&
      socket.emit("TDChatJoin", {
        roomId: taskInfo.chatroom.id,
      });
    taskInfo && setTaskStatus(taskInfo.status);
  }, [socket, taskInfo]);

  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleOpens = (e) => {
    e.preventDefault();
    setOpens(true);
  };

  const handleClose = () => setOpen(false);
  const handleCloses = () => setOpens(false);

  const requestTaskCancellation = () => {
    socket.emit("SERVICECANCELREQUEST", {
      senderId: auth.user.userId,
      receiverId:
        taskInfo.receiver.id === auth.user.userId
          ? taskInfo.provider.id
          : taskInfo.receiver.id,
      taskId: taskId,
      reason: document.getElementById("cancellationReason").value,
    });
    toastify("Service cancellation successfully requested.", toastType.success);
    handleCloses();
  };

  const requestTaskComplete = async () => {
    let text = document.getElementById("complete-description").value;

    if (text !== "") {
      setSending(true);
      const attachedFiles = await handleFileUpload();
      socket.emit("SERVICECOMPLETEREQUEST", {
        senderId: auth.user.userId,
        receiverId:
          taskInfo.receiver.id === auth.user.userId
            ? taskInfo.provider.id
            : taskInfo.receiver.id,
        taskId: taskId,
        text,
        attachedFiles,
      });

      setSending(false);
      toastify("Complete service successfully requested.", toastType.success);
    } else {
      toastify("Please fill the description area.", toastType.warning);
    }
    handleClose();
  };

  const handleFileUpload = async () => {
    const fileUploadPromises = files.map((file) => uploadFileToS3(file));
    const fileUploadResults = await Promise.all(fileUploadPromises);

    const fileNames = fileUploadResults.map((file) => file.Key);

    setFiles([]);
    return fileNames;
  };

  const uploadFileToS3 = async (file) => {
    const fileKey = `${uuidv4()}-${slugify(file.type)}.${file.name
      .split(".")
      .pop()}`;
    const bufferData = await getFileBuffer(file);
    const params = {
      Bucket: "tradetribestore",
      Key: fileKey,
      Body: bufferData,
    };

    return myBucket.upload(params).promise();
  };

  const getFileBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        file.bufferData = resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <>
      <main className="flex-1 order">
        <div className="container flex justify-center">
          {taskInfo && (
            <div className="grid grid-cols-4 gap-8 max-md:grid-cols-1">
              <div className="col-span-4 sm:col-span-3 md:max-lg:mr-[50px] max-md:order-1">
                <div className="pt-[19px] pb-[23px] px-[27px] border border-solid border-[#e9e9e9] rounded-[15px]">
                  <h3 className="text-[20px]">Task Requirements</h3>
                  <p className="mt-[22px] mb-[6px] text-main"></p>
                  <p>{taskInfo.requirement}</p>
                </div>
                <h2 className="text-[25px] my-[37px] text-main">Task Chat</h2>
                <Grid
                  container
                  component={Paper}
                  className={classes.chatSection}
                >
                  <TDChatBody
                    className={classes.messageArea}
                    socket={socket}
                    taskInfo={taskInfo}
                    taskStatus={taskStatus}
                    receiverAvatarLink={receiverAvatarLink}
                    providerAvatarLink={providerAvatarLink}
                    messages={messages}
                  />
                  <Divider />
                  <TDChatFooter
                    socket={socket}
                    taskStatus={taskStatus}
                    roomId={taskInfo.chatroom.id}
                    taskId={taskInfo.id}
                    providerId={taskInfo.providerId}
                    defaultReview={taskInfo.review}
                    oponent={
                      taskInfo.providerId === auth.user.userId
                        ? taskInfo.receiverId
                        : taskInfo.providerId
                    }
                  />
                </Grid>
              </div>
              <div className="col-span-4 sm:col-span-1 sm:ml-5 md:min-w-[250px] lg:max-xl:ml-[-20px] md:max-lg:ml-[-70px] max-md:mb-[25px] max-md:order-0 max-sm:gap-0">
                <h2 className="text-center mb-[15px]">
                  {taskInfo.service.title}
                </h2>
                <div className="flex flex-col items-center py-7 px-5 mt-[18px] mb-4 bg-[#f9f9f9] rounded-[20px]">
                  <h3>Points for this Task</h3>
                  <p className="mt-[21px] text-[50px] font-medium text-main">
                    {taskInfo.rate}
                  </p>
                  <p className="text-[13px]">Points</p>
                  {taskStatus === "ACCEPTED" &&
                    taskInfo.providerId === auth.user.userId && (
                      <Link
                        to="#"
                        className="flex w-full justify-center py-3 text-white text-center border-none bg-main rounded-full transition-colors duration-300 hover:bg-[#0B4E00]"
                        onClick={handleOpen}
                      >
                        Complete Service
                      </Link>
                    )}
                </div>
                <div className="pt-[28px] px-[19px] pb-[21px] mb-[15px] bg-[#f9f9f9] rounded-[20px]">
                  <h3>Task Details</h3>
                  <h4 className="mt-4 mb-0">Giver</h4>
                  <div className="flex items-center">
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-center"
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          (new Date() - new Date(taskInfo.provider.updatedAt)) /
                            (1000 * 60) <
                          5
                            ? "primary"
                            : "warning"
                        }
                      >
                        <Link to={`/user-profile/${taskInfo.provider.id}`}>
                          <Avatar
                            alt={`${taskInfo.provider.firstName} ${taskInfo.provider.lastName}`}
                            src={providerAvatarLink}
                          />
                        </Link>
                      </StyledBadge>
                    </Stack>
                    <div className="ml-[18px] mb-[-5px]">
                      <div className="flex items-center">
                        <Link to={`/user-profile/${taskInfo.provider.id}`}>
                          <h3 className="text-[20px] mr-[10px] cursor-pointer">
                            {`${taskInfo.provider.firstName} ${taskInfo.provider.lastName}`}
                          </h3>
                        </Link>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ fontSize: 13, color: "#1CC300", zIndex: 10 }}
                        />
                        <p className="ml-[5px]">
                          {providerRate
                            ? parseFloat(providerRate).toFixed(1)
                            : "0.0"}
                        </p>
                      </div>
                      {/* <p className="text-[15px]">Grahic Designer</p> */}
                    </div>
                  </div>
                  <h4 className="mt-4 mb-0">Receiver</h4>
                  <div className="flex items-center">
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-center"
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          (new Date() - new Date(taskInfo.receiver.updatedAt)) /
                            (1000 * 60) <
                          5
                            ? "primary"
                            : "warning"
                        }
                      >
                        <Link to={`/user-profile/${taskInfo.receiver.id}`}>
                          <Avatar
                            alt={`${taskInfo.receiver.firstName} ${taskInfo.receiver.lastName}`}
                            src={receiverAvatarLink}
                          />
                        </Link>
                      </StyledBadge>
                    </Stack>
                    <div className="ml-[18px] mb-[-5px]">
                      <div className="flex items-center">
                        <Link to={`/user-profile/${taskInfo.receiver.id}`}>
                          <h3 className="text-[20px] mr-[10px]">
                            {`${taskInfo.receiver.firstName} ${taskInfo.receiver.lastName}`}
                          </h3>
                        </Link>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{ fontSize: 13, color: "#1CC300", zIndex: 10 }}
                        />
                        <p className="ml-[5px]">
                          {receiverRate
                            ? parseFloat(receiverRate).toFixed(1)
                            : "0.0"}
                        </p>
                      </div>
                    </div>
                  </div>
                  {taskInfo.startedAt && (
                    <div className="mt-4 flex flex-col items-start">
                      <p className="font-medium">Acceptance Date</p>
                      <p>
                        {new Date(taskInfo.createdAt).toLocaleDateString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                  )}
                </div>
                {taskStatus === "ACCEPTED" && (
                  <button
                    onClick={handleOpens}
                    className="flex w-full justify-center py-3 text-white text-center border-none bg-[#FF000E] rounded-full transition-colors duration-300 hover:bg-[#b70f18]"
                  >
                    Cancel Service
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </main>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} className="max-md:max-w-[90vw] max-md:!px-5">
            <>
              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h2"
                className="mb-6"
              >
                Finalize the Service
              </Typography>
              <Typography
                id="transition-modal-title"
                variant="p"
                component="p"
                className="mb-2"
              >
                Please enter details and attach the files if any
              </Typography>
              <form className="flex flex-1 flex-col text-center">
                <Typography variant="h6" component="h3">
                  Description
                </Typography>
                <TextField
                  id="complete-description"
                  type="text"
                  className="mb-4"
                  label="Description"
                  variant="outlined"
                  placeholder="Enter here"
                  multiline
                  minRows={5}
                  maxRows={5}
                />
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h3"
                >
                  Add Attachments
                </Typography>
                <FileUpload files={files} setFiles={setFiles} />
                <button
                  className={
                    "py-3 px-7 mr-4 mt-4 mx-auto max-w-[200px] border-none outline-none rounded-full focus:outline-none bg-main text-white"
                  }
                  type="button"
                  onClick={requestTaskComplete}
                >
                  {sending ? (
                    <>
                      <span>Sending... </span>
                      <CircularProgress size="24" />
                    </>
                  ) : (
                    <>Submit</>
                  )}
                </button>
              </form>
            </>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opens}
        onClose={handleCloses}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens}>
          <Box sx={style} className="max-md:max-w-[90vw] max-md:!px-5">
            <>
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h4"
                className="mb-6"
              >
                Reason For Cancellation
              </Typography>
              <Typography
                id="transition-modal-title"
                variant="p"
                component="p"
                className="!mb-6"
              >
                Please enter details of cancellation.
              </Typography>
              <form className="flex flex-1 flex-col text-center">
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h3"
                  className="text-left"
                >
                  Description
                </Typography>
                <TextField
                  id="cancellationReason"
                  style={{ width: "100%" }}
                  type="text"
                  label="Description"
                  variant="outlined"
                  placeholder="Enter here"
                  multiline
                  minRows={8}
                  maxRows={8}
                />
                <button
                  className={
                    "py-3 px-7 mr-4 my-4 mx-auto max-w-[200px] border-none outline-none rounded-full focus:outline-none bg-[#FF000E] text-white"
                  }
                  type="button"
                  onClick={requestTaskCancellation}
                >
                  Submit
                </button>
              </form>
            </>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Task);
