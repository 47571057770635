import React from "react";
import { Link } from "react-router-dom";

import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container !block">
        <div className="footer-middle">
          <div className="grid grid-cols-5 gap-3 max-xl:grid-cols-1 max-xl:gap-1 max-lg:grid-cols-1">
            <div className="col-span-2 cursor-default">
              <p className="footer-text-big text-left max-xl:!pl-0">
                <span>TRADE</span>
                <span className="text-black">IT</span>
                <span>FWD</span>
                <sup className="text-xs -top-[1.5em]">TM</sup>
              </p>
              <ul className="list-none footer-text-big !text-2xl mt-1 max-xl:!pl-0">
                <li className="mb-2 hover:text-[#1cc400] transition-all duration-300">
                  Give something.
                </li>
                <li className="mb-2 hover:text-[#1cc400] transition-all duration-300">
                  Get something.
                </li>
                <li className="mb-2 hover:text-[#1cc400] transition-all duration-300">
                  Pay nothing.
                </li>
              </ul>
            </div>
            <div className="col-span-3 pl-12 max-xl:pl-0">
              <div className="grid grid-cols-2 gap-3 max-md:grid-cols-1">
                <div className="text-left">
                  <p className="footer-text mb-6">Learn More</p>
                  <li className="footer-text-li mb-2">
                    <Link to="/how-it-works" className="footer-link">
                      How It Works
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <Link to="/help" className="footer-link">
                      Help
                    </Link>
                    <span>&nbsp;/&nbsp;</span>
                    <Link to="/contact-us" className="footer-link">
                      Contact Us
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <Link to="/privacy-policy" className="footer-link">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <Link to="/terms-condition" className="footer-link">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <Link to="/about-us" className="footer-link">
                      About Us
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <Link to="/faq" className="footer-link">
                      FAQ
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <span>&nbsp;</span>
                  </li>
                </div>
                <div className="text-left">
                  <p className="footer-text mb-6">Get in Touch</p>
                  <li className="footer-text-li mb-2">
                    <Link to="/contact-us" className="footer-link">
                      Email Us
                    </Link>
                  </li>
                  <li className="footer-text-li mb-2">
                    <a
                      href="mailto:support@tradeitfwd.com"
                      className="footer-link"
                    >
                      support@tradeitfwd.com
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p className="footer-text-small footer-copyright !pt-8 max-xl:!pl-0">
            TM & © 2024 TRADEITFWD, LLC
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
