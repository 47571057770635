import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const PrivacyPolicy = ({ auth }) => {
  return (
    <div className="container flex items-center">
      <div className="pb-16">
        <h1 className="my-8 max-sm:text-4xl">Privacy Policy</h1>
        <h3 className="my-2">Last Updated: 5/30/2024</h3>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> ("us",
          "we", or "our") operates{" "}
          <a
            href="https://tradeitfwd.com"
            className="!underline"
            target="_blank"
            rel="noreferrer"
          >
            tradeitfwd.com
          </a>{" "}
          (the "Site"). This page informs you of our policies regarding the
          collection, use, and disclosure of personal data when you use our Site
          and the choices you have associated with that data.
        </p>
        <p className="py-2">
          This policy notice describes how and why we might collect, store, use,
          and/or share (“process”) your information when you use our services
          (“Services”), such as when you:
        </p>
        <ul className="pl-4" style={{ listStyleType: `"-"` }}>
          <li className="pl-1">
            Visit our websites at{" "}
            <a
              href="https://tradeitfwd.com"
              className="!underline"
              target="_blank"
              rel="noreferrer"
            >
              https://tradeitfwd.com
            </a>
            , or any website of ours that links to this privacy notice.{" "}
          </li>
          <li className="pl-1">
            Engage with us in other related ways including any sales, marketing,
            or events
          </li>
        </ul>
        <h2 className="py-2 max-sm:text-2xl">
          1. Information Collection and Use
        </h2>
        <p className="py-2">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>
        <h5 className="py-1">Types of Data Collected</h5>
        <p className="py-1 font-bold">a. Personal Data</p>
        <p className="py-1">
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>Job titles </li>
          <li>Usernames </li>
          <li>Passwords</li>
          <li>Contact or authentication data</li>
          <li>Contact preferences</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p>
          <strong>Sensitive Information.</strong> We do not process sensitive
          information.
        </p>
        <p className="py-1 font-bold">b. Usage Data</p>
        <p className="py-1">
          We may also collect information on how the Site is accessed and used
          ("Usage Data"). This Usage Data may include information such as your
          computer's Internet Protocol (IP) address, browser type, browser
          version, the pages of our Site that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers,
          and other diagnostic data.
        </p>
        <p className="py-1 font-bold">c. Tracking & Cookies Data</p>
        <p className="py-1">
          We use cookies and similar tracking technologies to track the activity
          on our Site and hold certain information. For full details see the
          Cookie Policy.
        </p>
        <h2 className="py-2 max-sm:text-2xl">2. Use of Data</h2>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> uses the
          collected data for various purposes:
        </p>
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer support</li>
          <li>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent, and address technical issues</li>
          <li>
            To provide you with news, special offers, and general information
            about other goods, services, and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information
          </li>
        </ul>
        <h2 className="py-2 max-sm:text-2xl">3. Transfer of Data</h2>
        <p className="py-2">
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country, or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>
        <h2 className="py-2 max-sm:text-2xl">4. Disclosure of Data</h2>
        <p className="py-2">
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> may
          disclose your Personal Data in the good faith belief that such action
          is necessary to:
        </p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>
            To protect and defend the rights or property of{" "}
            <span className="text-[#1cc400]">TRADE</span>
            <span>IT</span>
            <span className="text-[#1cc400]">FWD</span>
            <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>
          </li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>
        <h2 className="py-2 max-sm:text-2xl">5. Security of Data</h2>
        <p className="py-2">
          The security of your data is important to us, but remember that no
          method of transmission over the Internet is 100% secure. We take
          appropriate security measures to prevent unauthorized access,
          disclosure, modification, or unauthorized destruction of data.
        </p>
        <h2 className="py-2 max-sm:text-2xl">6. Data Requests and Inquiries</h2>
        <p className="py-2">
          To request information about your data or to request your data be
          deleted email{" "}
          <a href="mailto:support@tradeitfwd.com">support@tradeitfwd.com</a>
        </p>
        <h1 className="pt-8 pb-4 max-sm:text-4xl">Cookie Policy</h1>
        <p className="py-2">
          This document informs Users about the technologies that help this
          Application to achieve the purposes described below. Such technologies
          allow the Owner to access and store information (for example by using
          a Cookie) or use resources (for example by running a script) on a
          User’s device as they interact with this Application.
        </p>
        <p className="py-2">
          For simplicity, all such technologies are defined as "Trackers" within
          this document – unless there is a reason to differentiate.
        </p>
        <p className="py-2">
          For example, while Cookies can be used on both web and mobile
          browsers, it would be inaccurate to talk about Cookies in the context
          of mobile apps as they are a browser-based Tracker. For this reason,
          within this document, the term Cookies is only used where it is
          specifically meant to indicate that particular type of Tracker.
        </p>
        <p className="py-2">
          Some of the purposes for which Trackers are used may also require the
          User's consent. Whenever consent is given, it can be freely withdrawn
          at any time following the instructions provided in this document. This
          Application only uses Trackers managed directly by the Owner
          (so-called “first-party” Trackers).
        </p>
        <p className="py-2">
          The validity and expiration periods of first-party Cookies and other
          similar Trackers may vary depending on the lifetime set by the Owner.
          Some of them expire upon termination of the User’s browsing session.
        </p>
        <p className="py-2 font-bold">
          Activities strictly necessary for the operation of this Application
          and delivery of the Service
        </p>
        <p className="py-2">
          This Application uses so-called “technical” Cookies and other similar
          Trackers to carry out activities that are strictly necessary for the
          operation or delivery of the Service.
        </p>
        <p className="py-2">
          How to manage preferences and provide or withdraw consent
        </p>
        <p className="py-2">
          There are various ways to manage Tracker related preferences and to
          provide and withdraw consent, where relevant: Users can manage
          preferences related to Trackers from directly within their own device
          settings, for example, by preventing the use or storage of Trackers.
        </p>
        <p className="py-2">
          Additionally, whenever the use of Trackers is based on consent, Users
          can provide or withdraw such consent by setting their preferences
          within the cookie notice or by updating such preferences accordingly
          via the relevant consent-preferences privacy widget, if available. It
          is also possible, via relevant browser or device features, to delete
          previously stored Trackers, including those used to remember the
          User’s initial consent preferences.
        </p>
        <p className="py-2">
          Other Trackers in the browser’s local memory may be cleared by
          deleting the browsing history.
        </p>
        <p className="py-2 font-bold">Locating Tracker Settings</p>
        <p className="py-2">
          Users can, for example, find information about how to manage Cookies
          in the most commonly used browsers at the following addresses:
        </p>
        <ul>
          <li>Google Chrome</li>
          <li>Mozilla Firefox</li>
          <li>Apple Safari</li>
          <li>Microsoft Internet Explorer</li>
          <li>Microsoft Edge</li>
          <li>Brave</li>
          <li>Opera</li>
        </ul>
        <p className="py-2">
          Users may also manage certain categories of Trackers used on mobile
          apps by opting out through relevant device settings such as the device
          advertising settings for mobile devices, or tracking settings in
          general (Users may open the device settings and look for the relevant
          setting).
        </p>
        <p className="py-2 font-bold">
          Consequences of denying the use of Trackers
        </p>
        <p className="py-2">
          Users are free to decide whether or not to allow the use of Trackers.
          However, please note that Trackers help this Application to provide a
          better experience and advanced functionalities to Users (in line with
          the purposes outlined in this document). Therefore, if the User
          chooses to block the use of Trackers, the Owner may be unable to
          provide related features.
        </p>
        {!auth.isAuthenticated && (
          <div className="flex justify-end pt-16">
            <Link
              to="/register"
              className="!bg-main !border-main first-letter:hover:text-white py-3 px-8 group border border-solid bg-transparent !text-white capitalize rounded-full transition-all duration-300 hover:!bg-second hover:!border-second"
            >
              Sign Up
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(PrivacyPolicy);
