import { toast } from "react-toastify";

export const toastType = {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  };

export const toastify = (message, type) => {
  const toastOptions = {
    position: "top-center",
    containerId: "main",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  switch (type) {
    case toastType.success:
      toast.success(message, toastOptions);
      break;
    case toastType.error:
      toast.error(message, toastOptions);
      break;
    case toastType.warning:
      toast.warning(message, toastOptions);
      break;
    default:
      toast.info(message, toastOptions);
      break;
  }
};
