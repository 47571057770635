import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Stepper from "react-stepper-horizontal";

import ServiceNamePage from "./components/service_name";
import ServicePhotoPage from "./components/service_photo";
import ServiceRatePage from "./components/service_rate";
import ServiceDetailPage from "./components/service_detail";
import ServiceLocationPage from "./components/service_location";
import ServiceYourSelfPage from "./components/service_yourself";

import { serviceStore } from "../../actions/serviceAction.js";

import "./service.css";
import ServiceReferencePage from "./components/service_reference";

const ServicePage = ({ serviceStore, service, auth }) => {
  const [stepperVal, setState] = useState(0);

  let nextRef = useRef(null);

  let history = useHistory();

  useEffect(() => {
    if (service.serviceData["flag"]) {
      history.push("/dashboard");
    }
  }, [history, service]);

  useEffect(() => {
    if (auth.user.isServiceRegisted) {
      history.push("/dashboard");
    }
  }, [auth, history]);

  const handleNext = () => {
    if (stepperVal === 6) {
      setState(6);
    } else {
      setState(stepperVal + 1);
    }
  };
  const handleBack = () => {
    if (stepperVal === 0) {
      setState(0);
    } else {
      setState(stepperVal - 1);
    }
  };

  let mainPage;
  let buttonDisable = false;
  switch (stepperVal) {
    case 0:
      mainPage = <ServiceNamePage />;
      buttonDisable = service.serviceData.serviceName === "";
      break;
    case 1:
      mainPage = <ServiceDetailPage />;
      buttonDisable = service.serviceData.serviceTellUs === "";
      break;
    case 2:
      mainPage = <ServiceRatePage />;
      buttonDisable =
        service.serviceData.serviceRate === 0 ||
        service.serviceData.serviceRateUs === "";
      break;
    case 3:
      mainPage = <ServiceReferencePage />;
      break;
    case 4:
      mainPage = <ServicePhotoPage next={nextRef} />;
      buttonDisable = false;
      break;
    case 5:
      mainPage = <ServiceLocationPage />;
      break;
    case 6:
      mainPage = <ServiceYourSelfPage />;
      break;
    default:
      break;
  }

  return (
    <div className="service-form-container">
      <div className="service-form shadow-none">
        <div className="service-form-content">
          <div className="mb-4">
            <Stepper
              steps={[
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" },
              ]}
              size={36}
              activeStep={stepperVal}
              completeColor={"#1CC300"}
              activeColor={"#1CC300"}
              defaultColor={"#D9D9D9"}
              circleFontColor={"white"}
              barStyle={"dotted"}
              completeBarColor={"#1CC300"}
            />
          </div>

          {mainPage}
          {stepperVal === 6 ? (
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <button
                  type="button"
                  className="btn btn-success btn-service-back text-white"
                  onClick={handleBack}
                >
                  Back
                </button>
              </div>
              <div className="col-span-1">
                <button
                  type="button"
                  className="btn btn-success btn-service-next text-white"
                  onClick={() => {
                    serviceStore({
                      flag: true,
                      navigator: () => {
                        history.push("/dashboard");
                      },
                    });
                  }}
                >
                  Finish
                </button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2">
              <div className="col-span-1">
                <button
                  type="button"
                  className="btn btn-success btn-service-back text-white"
                  onClick={handleBack}
                  disabled={stepperVal === 0}
                >
                  Back
                </button>
              </div>
              <div className="col-span-1">
                <button
                  ref={nextRef}
                  type="button"
                  className="btn btn-success btn-service-next text-white"
                  onClick={handleNext}
                  disabled={buttonDisable}
                >
                  Next
                </button>
              </div>
            </div>
          )}
          <br />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  service: state.service,
  auth: state.auth,
});
export default connect(mapStateToProps, { serviceStore })(ServicePage);
