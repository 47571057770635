import React, { useState } from "react";
import Cookies from "js-cookie";

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(!Cookies.get("cookieConsent"));

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { expires: 365 }); // Store consent in a cookie for 1 year
    setShowConsent(false);
  };

  return (
    <div
      className={
        showConsent
          ? "flex absolute top-0 justify-center w-full shadow-md z-50 bg-white"
          : "flex absolute top-0 opacity-0 invisible transition-all duration-300 justify-center w-full shadow-md z-50 bg-white"
      }
    >
      <div className="flex w-5/6 justify-between items-center p-3">
        <p>This website uses cookies to improve your experience.</p>
        <button
          onClick={handleAccept}
          className="px-4 py-2 border- hover:bg-black hover:text-white transition-all duration-300"
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
