import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";

import api from "../../api";
import { registerUser } from "../../actions/authAction.js";

import "./register.css";

const Register = ({ registerUser }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [check_1, setCheck_1] = useState(false);
  const [clickStatus, setClickStatus] = useState(false);
  const [emailSentStatus, setEmailSentStatus] = useState(false);

  const auth = useSelector((state) => state.auth);

  let history = useHistory();

  useEffect(() => {
    if (auth.flag === "success") {
      history.push("/login");
    } else {
      setClickStatus(false);
    }
  }, [auth, history]);

  const toastHandler = (state, msg) => {
    if (state === "success") {
      toast.success(msg, {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (state === "error") {
      toast.error(msg, {
        position: "top-center",
        containerId: "main",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const register = () => {
    if (
      firstName.length &&
      lastName.length &&
      password.length &&
      email.length
    ) {
      if (password === confirmPassword) {
        // if (check_1 && check_2) {
        if (check_1) {
          var userData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
          };
          setClickStatus(true);
          registerUser(userData, history, setClickStatus, setEmailSentStatus);
        } else {
          toastHandler("error", "Please accept policies before joining.");
        }
      } else {
        toastHandler("error", "Those passwords didn’t match. Try again.");
      }
    } else {
      toastHandler("error", "Please fill all the fields!");
    }
  };

  const resendEmailHandler = () => {
    if (firstName.length && lastName.length && email.length) {
      api
        .post("/auth/resend-email", {
          firstName,
          lastName,
          email,
        })
        .then((res) => {
          toast.success(
            "New verification email is successfully sent. Please, check your email...",
            {
              position: "top-center",
              containerId: "main",
              autoClose: 3500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        })
        .catch((error) => {
          toast.error("Internal Server Error(s)", {
            position: "top-center",
            containerId: "main",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  return (
    <div className="register-form-container flex-1">
      {emailSentStatus ? (
        <div className="register-form flex flex-col justify-center text-center ">
          <h2>Email Verification Link Sent</h2>
          <p className="px-4 pt-12">
            <span className="text-second uppercase">
              <span className="text-[#1cc400]">Trade</span>
              <span>IT</span>
              <span className="text-[#1cc400]">Fwd</span>
              <sup
                className="text-[#1cc400]"
                style={{ fontSize: "12px", top: "-1em" }}
              >
                TM
              </sup>
            </span>{" "}
            sent Email Verification Link to your email.
            <br />
            Please check it and verify your email for sign up.
          </p>
          <div
            className="pl-0 pt-4 text-center underline underline-offset-4 register-text-me transition-all duration-300 hover:text-main cursor-pointer"
            onClick={resendEmailHandler}
          >
            Click here to have email re-sent.
          </div>
          <Link
            to="#"
            className="mt-4 text-main transition-all duration-all text-lg hover:underline"
          >
            Back To Home
          </Link>
        </div>
      ) : (
        <form className="register-form">
          <div className="register-form-content">
            <p className="register-text-login">Join The Community</p>
            <p className="register-text mb-4">
              Join <span className="text-[#1cc400]">TRADE</span>
              <span>IT</span>
              <span className="text-[#1cc400]">FWD</span>
              <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> to get
              free services in exchange for sharing yours!
            </p>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control !py-2.5 rounded-xl"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control !py-2.5 rounded-xl"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="email"
                className="form-control !py-2.5 rounded-xl"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="password"
                className="form-control !py-2.5 rounded-xl "
                placeholder="Password"
                value={password}
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group mt-3">
              <input
                type="password"
                className="form-control !py-2.5 rounded-xl "
                placeholder="Confirm Password"
                value={confirmPassword}
                autoComplete="confirm-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="form-group mt-3 !mb-2">
              <div className="grid grid-cols-12">
                <div className="col-span-1">
                  <input
                    type="checkbox"
                    placeholder="Condition"
                    checked={check_1}
                    onChange={(e) => setCheck_1(e.target.checked)}
                    className="w-4 h-4 m-1"
                  />
                </div>
                <div className="col-span-11">
                  <label className="register-text-me !p-0">
                    I accept the <span className="text-[#1cc400]">TRADE</span>
                    <span>IT</span>
                    <span className="text-[#1cc400]">FWD</span>
                    <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>
                    {" "}
                    <Link
                      to="/terms-condition"
                      className="text-main whitespace-nowrap transition-all duration-all text-[13px] hover:underline"
                    >
                      Terms & Conditions
                    </Link>{" "}
                    <span>and</span>
                    {" "}
                    <Link
                      to="/privacy-policy"
                      className="text-main whitespace-nowrap transition-all duration-all text-[13px] hover:underline"
                    >
                      Privacy Policy
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group mt-3 btn-center">
              <button
                type="button"
                className="btn btn-success btn-login text-white"
                disabled={clickStatus}
                onClick={register}
              >
                Join
              </button>
            </div>
            <br />
          </div>
        </form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(Register);
