import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import aws from "aws-sdk";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Avatar, Stack } from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";

const AQChatHeader = ({ oponents }) => {
  const [avatarLink, setAvatarLink] = useState("");
  const [showBackBtn, setShowBackBtn] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (oponents[0].media) {
      const s3 = new aws.S3();
      let params = {
        Bucket: "tradetribestore",
        Key: oponents[0].media.url,
      };
      let promise = s3.getSignedUrlPromise("getObject", params);
      promise.then(
        (url) => {
          setAvatarLink(url);
        },
        (err) => {
          console.error(err);
        }
      );
    }
    setShowBackBtn(window.location.pathname !== "/chat-history");
  }, [oponents]);

  return (
    <div className="flex justify-between items-center px-[25px] py-[20px]">
      <div className="left flex items-center">
        {showBackBtn && (
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => history.goBack()}
            style={{ fontSize: 16, color: "#292D32" }}
            className="mr-[10px]"
          />
        )}
        <Stack direction="row" spacing={2} className="justify-center mr-[5px]">
          <Link to={`/user-profile/${oponents[0].id}`}>
            <Avatar
              alt={`${oponents[0].firstName} ${oponents[0].lastName}`}
              src={avatarLink}
            />
          </Link>
        </Stack>
        <div className="">
          <h3 className="text-[18px]">
            <Link to={`/user-profile/${oponents[0].id}`}>
              {`${oponents[0].firstName} ${oponents[0].lastName}`}{" "}
            </Link>
          </h3>
          <div className="flex items-center">
            <CircleIcon
              className={
                (new Date() - new Date(oponents[0].updatedAt)) / (1000 * 60) < 5
                  ? "text-main max-w-[13px]"
                  : "text-[#FFEE00] max-w-[13px]"
              }
            />
            {(new Date() - new Date(oponents[0].updatedAt)) / (1000 * 60) <
            5 ? (
              <p className="text-[13px] text-[#989898] mb-0">Active Now</p>
            ) : (
              <p className="text-[13px] text-[#989898] mb-0">Away</p>
            )}
          </div>
        </div>
      </div>
      <div className="right">
        {/* <MoreHorizIcon className="text-[#000] min-w-[40px] min-h-[40px]" /> */}
      </div>
    </div>
  );
};

export default AQChatHeader;
