import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { Avatar, Button, CircularProgress, Stack } from "@mui/material";

import { serviceUploadPhoto } from "../../../actions/serviceAction";
import { setAvatar } from "../../../actions/authAction";
import { uploadFile } from "../../../util/s3";

import { useAtom } from "jotai";
import { serviceRegistration } from "../../../store/jotai";

const ServicePhotoPage = ({ serviceUploadPhoto, setAvatar, next }) => {
  const service = useSelector((state) => state.service);

  const hiddenFileInput = React.useRef(null);

  const [photoName, setPhotoName] = useState("");
  const [photoWidth, setPhotoWidth] = useState(50);
  const [photoHeight, setPhotoHeight] = useState(50);
  const [showSpinner, setShowSpinner] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const [regTemp, setRegTemp] = useAtom(serviceRegistration);
  const [regTempStatus, setRegTempStatus] = useState(null);
  useEffect(() => {
    setRegTempStatus(regTemp);
  }, [regTemp]);

  useEffect(() => {
    setPhotoWidth(150);
    setPhotoHeight(150);
  }, [service]);


  useEffect(() => {
    if (photoName !== "") {
      serviceUploadPhoto(photoName);
      setAvatar(photoName);
    }
  }, [photoName, serviceUploadPhoto, setAvatar]);

  useEffect(() => {
    if (showSpinner && uploadPercent < 100) {
      next.current.disabled = true;
    } else {
      next.current.disabled = false;
    }
  }, [next, showSpinner, uploadPercent]);

  const handleFileChange = async (e) => {
    const fileUpload = e.target.files[0];
    setShowSpinner(true);
    await uploadFile(fileUpload, setPhotoName, setUploadPercent);
    setRegTemp({
      ...regTemp,
      avatar: URL.createObjectURL(fileUpload)
    });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <p className="service-text-service">Add your Photo</p>
      <p className="service-text mt-6 mb-16 leading-none">
        Upload your photo in which your face is clear it
        <br />
        helps you get more clients.
      </p>

      <div className="mb-28">
        <div className="service-avatar">
          <div className="service-photo">
            <Stack
              direction="row relative"
              spacing={2}
              className="justify-center z-10"
            >
              <Avatar
                alt="Uploaded Avatar"
                src={regTempStatus ? regTempStatus.avatar : ""}
                sx={{ width: photoWidth, height: photoHeight }}
              />
              {showSpinner && uploadPercent < 100 && (
                <div className="absolute flex justify-center items-center w-full h-full z-10 top-0 left-0 bg-slate-400 opacity-50 rounded-full">
                  <CircularProgress className="z-20" />
                </div>
              )}
              {showSpinner && uploadPercent < 100 && (
                <div className="absolute flex justify-center items-center w-full h-full z-10 top-0 left-0 rounded-full">
                  <span className="text-white">{uploadPercent}%</span>
                </div>
              )}
            </Stack>
            <button className="absolute bottom-0 right-0 w-10 h-10 rounded-full bg-main border-main text-white hover:bg-second hover:border-second transition-all z-10" onClick={handleClick}>
              <i className="fa fa-upload"></i>
            </button>
          </div>
        </div>
      </div>
      <input
        type="file"
        className="form-control mt-1"
        // placeholder="Enter here"
        ref={hiddenFileInput}
        onChange={(e) => handleFileChange(e)}
        style={{ display: "none" }}
      />
      <div className="form-group mt-3"></div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  service: state.service,
});
export default connect(mapStateToProps, { serviceUploadPhoto, setAvatar })(
  ServicePhotoPage
);
