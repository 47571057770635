export const convertDateTime = (dateTimeString) => {
  // Create a new Date object from the dateTimeString
  const dateTime = new Date(dateTimeString);

  // Get the current date and time
  const now = new Date();

  // Calculate the difference between the two dates in milliseconds
  const diffInMs = Math.abs(now - dateTime);

  // Convert the difference to minutes
  const diffInMinutes = Math.floor(diffInMs / 1000 / 60);

  // Convert the difference to hours
  const diffInHours = Math.floor(diffInMinutes / 60);

  // Convert the difference to days
  const diffInDays = Math.floor(diffInHours / 24);

  // Convert the difference to months
  const diffInMonths = Math.floor(diffInDays / 30);

  // Convert the difference to years
  const diffInYears = Math.floor(diffInMonths / 12);

  // Check if the difference is less than 1 minute
  if (diffInMinutes < 1) {
    return "Just now";
  }
  // Check if the difference is less than 1 hour
  else if (diffInMinutes < 60) {
    // Check if the difference is equal to 1 minute
    if (diffInMinutes === 1) {
      return `${diffInMinutes} min ago`;
    }
    // Otherwise, the difference is more than 1 minute
    else {
      return `${diffInMinutes} mins ago`;
    }
  }
  // Check if the difference is less than 1 day
  else if (diffInHours < 24) {
    // Check if the difference is equal to 1 hour
    if (diffInHours === 1) {
      return `${diffInHours} hour ago`;
    }
    // Otherwise, the difference is more than 1 hour
    else {
      return `${diffInHours} hours ago`;
    }
  }
  // Check if the difference is less than 1 month
  else if (diffInDays < 30) {
    // Check if the difference is equal to 1 day
    if (diffInDays === 1) {
      return `${diffInDays} day ago`;
    }
    // Otherwise, the difference is more than 1 day
    else {
      return `${diffInDays} days ago`;
    }
  }
  // Check if the difference is less than 1 year
  else if (diffInMonths < 12) {
    // Check if the difference is equal to 1 month
    if (diffInMonths === 1) {
      return `${diffInMonths} month ago`;
    }
    // Otherwise, the difference is more than 1 month
    else {
      return `${diffInMonths} months ago`;
    }
  }
  // Otherwise, the difference is more than 1 year
  else {
    // Check if the difference is equal to 1 year
    if (diffInYears === 1) {
      return `${diffInYears} year ago`;
    }
    // Otherwise, the difference is more than 1 year
    else {
      return `${diffInYears} years ago`;
    }
  }
};
