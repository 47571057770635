import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    // <main className="flex flex-col justify-center items-center flex-1 page-404">
    //     <h1 className="error-code">404</h1>
    //     <h2 className="mb-3">Page not found.</h2>
    //     <Link to="/">Back to Main Page</Link>
    // </main>
    <main className="w-full py-10 font-mono">
      <div className="flex justify-center">
        <div className="min-w-full lg:min-w-[920px]">
          <div className="four_zero_four_bg">
            <h1 className="text-center ">404</h1>
          </div>

          <div className="contant_box_404 text-center">
            <h3 className="h2">Look like you're lost</h3>

            <p>the page you are looking for not avaible!</p>

            <Link to="/" className="link_404">
              Go to Home
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageNotFound;
