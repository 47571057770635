import {
  SET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from "./types";

export const setCurrentNotifications = (notifications) => (dispatch) => {
    try {
      dispatch({
        type: SET_NOTIFICATIONS,
        payload: notifications,
      });
    } catch (err) {
      console.error(err);
    }
};

// Add an item to the favorites list
export const addNewNotification = (notification) => (dispatch) => {
  try {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: notification,
    });
  } catch (err) {
    console.error(err);
  }
};

// Remove an item from the favorites list
export const removeNotification = (id) => (dispatch) => {
  try {
    dispatch({
      type: REMOVE_NOTIFICATION,
      payload: id,
    });
  } catch (err) {
    console.error(err);
  }
};

// Clear all items from the favorites list
export const clearNotifications = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_NOTIFICATIONS,
    });
  } catch (err) {
    console.error(err);
  }
};