// Import api from the ../api directory
import api from "../api";

// Import types for GET_ERRORS and GET_SUCCESS
import { GET_ERRORS, GET_SUCCESS, SET_AVATAR } from "./types";

// Get token from localStorage
const token = localStorage.jwtToken;

// Set config headers with x-access-token
const config = {
  headers: {
    "x-access-token": token,
  },
};

// Initialize service variables
var serviceName = "";
var serviceTellUs = "";
var serviceRate = 0;
var serviceRateUs = "";
var servicePhotoUrl = "";
var servicePresentation = "";
var serviceType = "virtual";
var servicePortfolios = [];
var serviceCertifications = [];
var notificationEnabled = true;

// Export serviceStore function to store service data
export const serviceStore = (serviceData) => (dispatch) => {
  try {
    // If flag is true, post default service data
    if (serviceData["flag"]) {
      const data = {
        serviceName,
        serviceTellUs,
        serviceRate,
        serviceRateUs,
        servicePhotoUrl,
        servicePresentation,
        serviceType,
        servicePortfolios,
        serviceCertifications,
        notificationEnabled,
      };
      api
        .post("/api/service/saveDefaultServiceData", data, config)
        .then((res) => {
          // If success, dispatch GET_SUCCESS type
          if (res.data["success"]) {
            const response = {
              flag: true,
            };
            dispatch({
              type: GET_SUCCESS,
              payload: response,
            });
            serviceData.navigator && serviceData.navigator();
          } else {
            const response = {
              flag: false,
            };
            dispatch({
              type: GET_ERRORS,
              payload: response,
            });
            serviceData.navigator && serviceData.navigator();
          }
        })
        .catch((error) => {
          // Catch errors and dispatch GET_ERRORS type
          const data = {
            serviceName,
            serviceTellUs,
            serviceRate,
            serviceRateUs,
            servicePhotoUrl: "",
            servicePresentation,
            serviceType,
            servicePortfolios,
            serviceCertifications,
          };
          dispatch({
            type: GET_ERRORS,
            payload: data,
          });
          serviceData.navigator && serviceData.navigator();
          console.error(error);
        });
    } else {
      // Else, set service variables based on num
      switch (serviceData["num"]) {
        case 1:
          serviceName = serviceData["name"];
          break;
        case 2:
          serviceTellUs = serviceData["tellUs"];
          serviceType = serviceData["type"];
          break;
        case 3:
          serviceRate = serviceData["rate"];
          serviceRateUs = serviceData["rateUs"];
          break;
        case 4:
          servicePortfolios = serviceData["portfolios"];
          serviceCertifications = serviceData["certifications"];
          break;
        case 5:
          servicePhotoUrl = serviceData["value"];
          break;
        case 6:
          servicePresentation = serviceData["yourSelf"];
          notificationEnabled = serviceData["notificationEnabled"];
          break;
        default:
          break;
      }
      // Dispatch GET_SUCCESS type
      const data = {
        serviceName,
        serviceTellUs,
        serviceRate,
        serviceRateUs,
        servicePhotoUrl,
        servicePresentation,
        serviceType,
        servicePortfolios,
        serviceCertifications,
        notificationEnabled,
      };
      dispatch({
        type: GET_SUCCESS,
        payload: data,
      });
    }
  } catch (err) {
    // Catch errors
    console.error("error");
  }
};

// Export serviceUploadPhoto function to upload photo
export const serviceUploadPhoto = (fileUrl) => (dispatch) => {
  try {
    // Set servicePhotoUrl to fileUrl
    servicePhotoUrl = fileUrl;
    api.post("/api/media/save", { url: fileUrl }).then((res) => {
      // Set servicePhotoUrl to res.data.data.id
      servicePhotoUrl = res.data.data.id;
      // Dispatch
      const data = {
        serviceName,
        serviceTellUs,
        serviceRate,
        serviceRateUs,
        servicePhotoUrl,
        servicePresentation,
        serviceType,
        servicePortfolios,
        serviceCertifications,
        notificationEnabled,
      };
      dispatch({
        type: GET_SUCCESS,
        payload: data,
      });
    });
  } catch (err) {
    // Catch errors
    console.error("error");
  }
};

export const serviceUpdatePhoto = (fileUrl) => (dispatch) => {
  try {
    // Set servicePhotoUrl to fileUrl
    servicePhotoUrl = fileUrl;
    api.post("/api/media/save", { url: fileUrl }).then((res) => {
      // Set servicePhotoUrl to res.data.data.id
      servicePhotoUrl = res.data.data.id;
      // Dispatch
      const data = {
        serviceName,
        serviceTellUs,
        serviceRate,
        serviceRateUs,
        servicePhotoUrl,
        servicePresentation,
        serviceType,
        servicePortfolios,
        serviceCertifications,
        notificationEnabled,
      };
      api
        .post("/api/users/update-avatar", { id: res.data.data.id })
        .catch((error) => {
          console.error(error);
        });
      dispatch({
        type: GET_SUCCESS,
        payload: data,
      });
      dispatch({
        type: SET_AVATAR,
        payload: {
          avatar: fileUrl,
        },
      });
    });
  } catch (err) {
    // Catch errors
    console.error("error");
  }
};
