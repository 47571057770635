// Import action types
import axiosClient from "api";
import {
  ADD_TO_FAVORITES,
  REMOVE_FROM_FAVORITES,
  CLEAR_FAVORITES,
  SET_CURRENT_FAVORITES,
} from "../actions/types";

// Initial state of favorites
const initialState = { list: [] };

// Reducer function to handle favorites
const favListReducer = (state = initialState, action) => {
  let tmp;
  switch (action.type) {
    // Set current favorites
    case SET_CURRENT_FAVORITES:
      console.log("SET_CURRENT_FAVORITES", action.payload);
      // Return the payload which contains the current favorites
      return { list: action.payload };
    // Add item to favorites
    case ADD_TO_FAVORITES:
      tmp = [...state.list];
      // Check if item is already in favorites
      if (!tmp.includes(action.payload)) {
        tmp.push(action.payload);
        axiosClient.post("/api/users/update-wishlist", {
          list: tmp
        });
        // Store favorites in local storage
        // localStorage.setItem("favorites", JSON.stringify(tmp));
      }
      return { list: tmp };
    // Remove item from favorites
    case REMOVE_FROM_FAVORITES:
      tmp = [...state.list];
      // Find index of item and remove it from array
      tmp.splice(tmp.indexOf(action.payload), 1);
      axiosClient.post("/api/users/update-wishlist", {
        list: tmp
      });
      // Store favorites in local storage
      // localStorage.setItem("favorites", JSON.stringify(tmp));
      return { list: tmp };
    // Clear all favorites
    case CLEAR_FAVORITES:
      // Return empty array
      axiosClient.post("/api/users/update-wishlist", {
        list: []
      });
      return { list: [] };
    // Default case
    default:
      return state;
  }
};

export default favListReducer;
