import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { serviceStore } from "../../../actions/serviceAction";
import CertificationImageUploadOnSignUp from "view/component/certificationImageUploadOnSignUp";
import PortfolioImageUploadOnSignUp from "view/component/portfolioImageUploadOnSignUp";
import { useAtom } from "jotai";
import { serviceRegistration } from "store/jotai";

const ServiceReferencePage = ({ serviceStore }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [portfolioList, setPortfolioList] = useState([]);
  const [certificationList, setCertificationList] = useState([]);

  const [regTemp] = useAtom(serviceRegistration);
  
  useEffect(() => {
    if (regTemp) {
      regTemp.portfolios && setPortfolioList(regTemp.portfolios);
      regTemp.certifications && setCertificationList(regTemp.certifications);
    }
  }, [regTemp]);

  useEffect(() => {
    serviceStore({
      num: 4,
      flag: false,
      portfolios: portfolioList,
      certifications: certificationList,
    });
  }, [portfolioList, certificationList, serviceStore]);

  return (
    <div>
      <div className="flex justify-center mb-2">
        <button
          className={
            "!outline-none !border-none p-4 min-w-[150px] rounded-tl-lg rounded-bl-lg" +
            (tabIndex === 0 ? " bg-main text-white" : "")
          }
          onClick={() => setTabIndex(0)}
        >
          Work Samples
        </button>
        <button
          className={
            "!outline-none !border-none p-4 min-w-[150px] rounded-tr-lg rounded-br-lg" +
            (tabIndex === 1 ? " bg-main text-white" : "")
          }
          onClick={() => setTabIndex(1)}
        >
          Certification
        </button>
      </div>
      {tabIndex === 0 ? (
        <PortfolioImageUploadOnSignUp
          items={portfolioList}
          setter={setPortfolioList}
        />
      ) : (
        <CertificationImageUploadOnSignUp
          items={certificationList}
          setter={setCertificationList}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  service: state.service,
});
export default connect(mapStateToProps, { serviceStore })(ServiceReferencePage);
