import React, { useState, useEffect } from "react";
import { serviceStore } from "../../../actions/serviceAction";
import { connect, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

const ServiceYourSelfPage = ({ auth, serviceStore }) => {
  const service = useSelector((state) => state.service);
  const [serviceYourSelf, setServiceYourSelf] = useState(
    service.serviceData["servicePresentation"]
  );
  const [notificationState, setNotificationState] = useState(
    service.serviceData["notificationEnabled"]
  );

  useEffect(() => {
    setServiceYourSelf(service.serviceData["servicePresentation"]);
  }, [service]);

  useEffect(() => {
    serviceStore({
      num: 6,
      yourSelf: serviceYourSelf,
      notificationEnabled: notificationState,
      flag: false,
    });
  }, [notificationState, serviceStore, serviceYourSelf]);

  return (
    <div>
      <p className="service-text-service leading-none mb-2">
        Tell us a little about yourself
      </p>
      <p className="service-text">
        This will be shared on your public profile.
      </p>
      <div className="form-group mt-3">
        <span className="service-text-label">Tell us more</span>
        <textarea
          className="form form-control border-none rounded-xl my-2"
          placeholder="Enter here"
          rows={5}
          onChange={(e) => setServiceYourSelf(e.target.value)}
          defaultValue={serviceYourSelf}
        />
      </div>
      <div className="pb-4 leading-none">
        <Checkbox
          className="mr-1"
          checked={notificationState}
          onClick={(e) => {
            setNotificationState(e.target.checked);
          }}
        />
        Enable Email Push Notifications for Inquiries and Service Requests.
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  service: state.service,
});
export default connect(mapStateToProps, { serviceStore })(ServiceYourSelfPage);
