import React from "react";

const AboutUs = () => {
  return (
    <div className="container flex items-center">
      <div className="my-16">
        <h1 className="mb-4 max-sm:text-4xl">About Us</h1>
        <p className="my-2">
          <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> is a collective of like-minded individuals who recognize
          the value in creating a larger scale trade-based service economy, an
          economy that places human connection above economic status. <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>&nbsp;takes the ancient practice of trading, fuses it with modern technology
          and takes it to a whole new level, allowing it to realize its full
          power and potential to positively impact the world. With <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>,
          services are made available to all people, regardless of social or
          financial status.
        </p>
        <p className="my-2">
          <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> was founded in 2022 by Scott Parietti after he had been
          exposed to many yoga/intentional/conscious communities throughout the
          years and saw how people in those communities shared, gifted and
          traded with no financial compensation and how this practice brought
          them closer, created bonds and expanded the love in those communities.
          One such community is Burning Man, a yearly gathering that has grown
          from 35 people to 75,000 people over the course of 36 years and is
          sustained by a “gifting” economy (giving freely without always
          receiving directly from the person you gifted to). These communities
          inspired Scott to start thinking about how it might be possible to
          create this heart-centered type of economy on a larger scale.
        </p>
        <p className="my-2">
          Scott’s biggest burst of personal inspiration came when his long time
          friend, a world-class Kundalini Yoga teacher found herself in need of
          a math tutor for her daughter (Scott’s God-daughter) but not having
          the budget for it. At that moment he realized the solution to this
          problem, not just for his friend, but for many others in similar
          positions. If you can trade, you don’t need a budget! But <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>&nbsp;takes the idea a step further by eliminating the nearly impossible
          task of finding a single person to trade with who also wants the
          service you're offering… there is no need to trade one-on-one when you
          can <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>!
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
