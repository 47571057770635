import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { loginUser, logoutOnUnload } from "../../actions/authAction.js";

import "./login.css";

const LoginPage = ({ loginUser, logoutOnUnload }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isClicked, setClicked] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const auth = useSelector((state) => state.auth);

  let history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("verify") === "true") setIsVerified(true);
  }, []);

  useEffect(() => {
    setClicked(false);
    if (auth.isAuthenticated) {
      auth.status && history.push("/dashboard");
      !auth.status && history.push("/service");
      logoutOnUnload();
    }
  }, [auth, history, logoutOnUnload]);

  const login = () => {
    const userData = {
      email: email,
      password: password,
    };
    setClicked(true);
    loginUser(userData, history, setClicked);
  };

  return (
    <div className="login-form-container">
      <form className="login-form">
        <div className="login-form-content">
          <p className="login-text-login">Login</p>
          <p className="login-text py-[20px]">Enter your details to login</p>
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control !py-2.5 rounded-xl"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control !py-2.5 rounded-xl"
              placeholder="Password"
              value={password}
              autoComplete="true"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && login()}
            />
          </div>
          <div className="form-group mt-4">
            <div className="grid grid-cols-12">
              <div className="col-span-1">
                <input
                  type="checkbox"
                  placeholder="Password"
                  className="w-4 h-4 m-1"
                />
              </div>
              <div className="col-span-6">
                <label className="login-text-me">Remember me</label>
              </div>
              <div className="col-span-5 text-right">
                <Link
                  className="text-[#2cdd0e] hover:underline"
                  to="/forgot-password"
                >
                  Lost password?
                </Link>
              </div>
            </div>
          </div>
          <div className="form-group mt-3 btn-center pb-[40px] border-t-0 border-r-0 border-l-0 border-b border-solid border-b-[#e9e9e9]">
            <button
              type="button"
              className="btn btn-success btn-login text-white"
              onClick={login}
              disabled={isClicked}
            >
              Login
            </button>
          </div>
          {!isVerified ? (
            <p className="login-text text-[15px] pt-3">Not yet in the tribe?</p>
          ) : (
            <p className="login-text text-[15px] pt-3"></p>
          )}
          <div className="form-group mt-3 btn-center">
            <Link to="/register" className="btn btn-success btn-join inline-flex justify-center py-2.5 px-4 min-w-[180px] !text-white">
              Join Now
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser, logoutOnUnload })(
  LoginPage
);
