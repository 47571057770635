import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import axiosClient from "../../api";
import AQChatHeader from "./components/AQChatHeader";
import AQChatBody from "./components/AQChatBody";
import AQChatFooter from "./components/AQChatFooter";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    display: "block",
    width: "100%",
    maxWidth: "1065px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "50px",
    marginBottom: "100px",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "50vh",
    overflowY: "auto",
  },
});

const AskQuestion = ({ auth, socket }) => {
  const classes = useStyles();

  const location = useLocation();

  const [roomId] = useState(new URLSearchParams(location.search).get("roomId"));
  const [, setRoomInfo] = useState(null);
  const [messages, setMessages] = useState([]);
  const [oponents, setOponents] = useState([]);

  useEffect(() => {
    axiosClient
      .post("api/chat/aq-chatroom-data", { roomId })
      .then((res) => {
        setRoomInfo(res.data.data.room);
        setMessages(res.data.data.room.messages);
        let tmp = res.data.data.room.members.filter(
          (member) => member.id !== auth.user.userId
        );
        setOponents(tmp);

        socket.emit("AQChatJoin", {
          roomId,
        });
      })
      .catch(() => console.error);

    return () => {
      socket.emit("AQChatDisconnect", {
        roomId,
      });
    };
  }, [auth.user.userId, roomId, socket]);

  return (
    <main className="flex-1 ask-question px-4">
      <Grid container component={Paper} className={classes.chatSection}>
        {oponents.length > 0 && (
          <AQChatHeader oponents={oponents} />
        )}
        <Divider />
        <AQChatBody
          classes={classes}
          socket={socket}
          messages={messages}
          setMessages={setMessages}
        />
        <Divider />
        <AQChatFooter
          socket={socket}
          roomId={roomId}
          oponents={oponents}
          messages={messages}
          setMessages={setMessages}
        />
      </Grid>
    </main>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AskQuestion);
