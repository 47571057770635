import { Avatar, Badge, Box, Stack, styled, Tab, Tabs } from "@mui/material";
import axiosClient from "api";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import aws from "aws-sdk";
import { toastify, toastType } from "util/toastify";
import { setCurrentUser } from "actions/authAction";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // backgroundColor: "#1CC400",
    // color: "#1CC400",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanels(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanels-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanels.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a12yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const status = [
  "ACCEPTED",
  "REQUESTED",
  "REVIEWED",
  "REJECTED",
  "CANCELTASK",
  "CANCELACCEPTED",
  "CANCELREJECTED",
  "COMPLETETASK",
  "COMPLETEACCEPTED",
  "COMPLETEREJECTED",
];

const ReceivingServices = ({ socket, auth, setCurrentUser }) => {
  const [tasks, setTasks] = useState([]);
  const [values, setValues] = useState(0);

  const s3 = useMemo(() => new aws.S3(), []);
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;

    axiosClient
      .post("/api/task/get-receiving-services", {
        status: [status[0], status[4], status[6], status[7], status[9]],
      })
      .then(async (res) => {
        if (!isMounted) return;

        let tasks = [...res.data.data.tasks];
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].provider.media) {
            let params = {
              Bucket: "tradetribestore",
              Key: tasks[i].provider.media.url,
            };
            tasks[i].provider.media.url = await s3.getSignedUrlPromise(
              "getObject",
              params
            );
          }
        }
        if (isMounted) {
          setTasks(tasks);
        }
      })
      .catch((err) => {
        if (isMounted) console.error(err);
      });

    return () => {
      isMounted = false;
    };
  }, [s3]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get("type");
    switch (param) {
      case "SERVICEREQUESTED":
        setValues(1);
        break;
      case "SERVICEREJECTED":
      case "SERVICECANCELLED":
        setValues(3);
        break;
      case "SERVICECOMPLETED":
        setValues(2);
        break;
      default:
        setValues(0);
        break;
    }
  }, [location]);

  useEffect(() => {
    let statusList = [];
    switch (values) {
      case 0:
        statusList = [status[0], status[4], status[6], status[7], status[9]];
        break;
      case 1:
        statusList = [status[1]];
        break;
      case 2:
        statusList = [status[2], status[8]];
        break;
      case 3:
        statusList = [status[3], status[5]];
        break;
      default:
        statusList = [status[0], status[4], status[6], status[7], status[9]];
        break;
    }
    axiosClient
      .post("/api/task/get-receiving-services", {
        status: statusList,
      })
      .then(async (res) => {
        let tasks = [...res.data.data.tasks];
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].provider.media) {
            let params = {
              Bucket: "tradetribestore",
              Key: tasks[i].provider.media.url,
            };
            tasks[i].provider.media.url = await s3.getSignedUrlPromise(
              "getObject",
              params
            );
          }
        }
        setTasks(tasks);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [s3, values]);

  const handleChanges = (event, newValue) => {
    setValues(newValue);
    setTasks([]);
  };

  const rejectHandler = (taskId) => {
    let tmp = tasks.filter((item) => item.id !== taskId);
    let tmp1 = tasks.find((item) => item.id === taskId);
    setTasks(tmp);
    axiosClient
      .post("/api/task/reject-task", {
        taskId,
      })
      .then((_res) => {
        toastify("Service Request successfully cancelled.", toastType.success);
        console.log("SERVICECANCELLED", {
          amount: tmp1.rate,
          senderId: auth.user.userId,
          receiverId: [tmp1.receiverId, tmp1.providerId].filter(
            (item) => parseInt(item) !== auth.user.userId
          )[0],
          taskId,
        });
        socket.emit("SERVICECANCELLED", {
          amount: tmp1.rate,
          senderId: auth.user.userId,
          receiverId: [tmp1.receiverId, tmp1.providerId].filter(
            (item) => parseInt(item) !== auth.user.userId
          )[0],
          taskId,
        });
      })
      .catch((err) => {
        console.error(err);
        toastify("Internal Server Error(s)", toastType.error);
      });
  };

  return (
    <>
      <div className="flex justify-between items-center mt-[50px] max-xl:block">
        <h2 className="max-xl:mb-4 sm:text-center">Service I'm Receiving</h2>
        <Tabs
          value={values}
          onChange={handleChanges}
          indicatorColor="main"
          aria-label="my tab"
          className="text-[#000]"
        >
          <Tab
            className="max-sm:!mb-4"
            label="Accepted task"
            {...a12yProps(0)}
          />
          <Tab
            className="max-sm:!mb-4"
            label="Pending Requests"
            {...a12yProps(1)}
          />
          <Tab className="max-sm:!mb-4" label="Completed" {...a12yProps(2)} />
          <Tab className="max-sm:!mb-4" label="Cancelled" {...a12yProps(3)} />
        </Tabs>
      </div>
      <TabPanels value={values} index={0}>
        <Box>
          {tasks.length > 0 ? (
            tasks.map((item, index) => (
              <div
                key={`accepted-${index}`}
                className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
              >
                <div className="flex justify-between items-center">
                  <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                    {item.requirement}
                  </p>
                  <Link
                    to={`/user-profile/${item.provider.id}`}
                    className="flex items-center"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-center mt-6 mb-3"
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          (new Date() - new Date(item.provider.updatedAt)) /
                            (1000 * 60) <
                          5
                            ? "primary"
                            : "warning"
                        }
                      >
                        <Avatar
                          alt={`${item.provider.firstName} ${item.provider.lastName}`}
                          src={
                            item.provider.media ? item.provider.media.url : ""
                          }
                        />
                      </StyledBadge>
                    </Stack>
                    <p className="ml-[5px]">{`${item.provider.firstName} ${item.provider.lastName}`}</p>
                  </Link>
                </div>
                <div className="flex items-center justify-between">
                  <div className="point">
                    <h3 className="text-[12px] font-medium">Points</h3>
                    <p className="text-[15px] text-[#7c7c7c] font-medium">
                      {item.rate} pts
                    </p>
                  </div>
                  <div className="status">
                    <h3 className="text-[12px] font-medium">Status</h3>
                    <p className="text-[15px] text-main font-medium">
                      In Progress
                    </p>
                  </div>
                  <Link
                    to={`/task?taskId=${item.id}`}
                    className="text-[15px] text-main font-medium"
                  >
                    View
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Service In Progress
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanels>
      <TabPanels value={values} index={1}>
        <Box>
          {tasks.length > 0 ? (
            tasks.map((item, index) => (
              <div
                key={`accepted-${index}`}
                className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
              >
                <div className="flex justify-between items-center">
                  <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                    {item.requirement}
                  </p>
                  <Link
                    to={`/user-profile/${item.provider.id}`}
                    className="flex items-center"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-center mt-6 mb-3"
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          (new Date() - new Date(item.provider.updatedAt)) /
                            (1000 * 60) <
                          5
                            ? "primary"
                            : "warning"
                        }
                      >
                        <Avatar
                          alt={`${item.provider.firstName} ${item.provider.lastName}`}
                          src={
                            item.provider.media ? item.provider.media.url : ""
                          }
                        />
                      </StyledBadge>
                    </Stack>
                    <p className="ml-[5px]">{`${item.provider.firstName} ${item.provider.lastName}`}</p>
                  </Link>
                </div>
                <div className="flex items-center justify-between">
                  <div className="point">
                    <h3 className="text-[12px] font-medium">Points</h3>
                    <p className="text-[15px] text-[#7c7c7c] font-medium">
                      {item.rate} pts
                    </p>
                  </div>
                  <div className="status">
                    <h3 className="text-[12px] font-medium">Status</h3>
                    <p className="text-[15px] text-main font-medium">Pending</p>
                  </div>

                  <button
                    onClick={() => rejectHandler(item.id)}
                    className="text-[16px] py-2 px-4 my-1 text-white bg-[#ac1018] hover:bg-[#FF000E] font-medium border-0 rounded-full transition-all duration-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Requested Service
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanels>
      <TabPanels value={values} index={2}>
        <Box>
          {tasks.length > 0 ? (
            tasks.map((item, index) => (
              <div
                key={`accepted-${index}`}
                className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
              >
                <div className="flex justify-between items-center">
                  <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                    {item.requirement}
                  </p>
                  <Link
                    to={`/user-profile/${item.provider.id}`}
                    className="flex items-center"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-center mt-6 mb-3"
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          (new Date() - new Date(item.provider.updatedAt)) /
                            (1000 * 60) <
                          5
                            ? "primary"
                            : "warning"
                        }
                      >
                        <Avatar
                          alt={`${item.provider.firstName} ${item.provider.lastName}`}
                          src={
                            item.provider.media ? item.provider.media.url : ""
                          }
                        />
                      </StyledBadge>
                    </Stack>
                    <p className="ml-[5px]">{`${item.provider.firstName} ${item.provider.lastName}`}</p>
                  </Link>
                </div>
                <div className="flex items-center justify-between">
                  <div className="point">
                    <h3 className="text-[12px] font-medium">Points</h3>
                    <p className="text-[15px] text-[#7c7c7c] font-medium">
                      {item.rate} pts
                    </p>
                  </div>
                  <div className="status">
                    <h3 className="text-[12px] font-medium">Status</h3>
                    <p className="text-[15px] text-main font-medium">
                      Completed
                    </p>
                  </div>
                  <Link
                    to={`/task?taskId=${item.id}`}
                    className="text-[15px] text-main font-medium"
                  >
                    View
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Completed Service
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanels>
      <TabPanels value={values} index={3}>
        <Box>
          {tasks.length > 0 ? (
            tasks.map((item, index) => (
              <div
                key={`accepted-${index}`}
                className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1"
              >
                <div className="flex justify-between items-center">
                  <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                    {item.requirement}
                  </p>
                  <Link
                    to={`/user-profile/${item.provider.id}`}
                    className="flex items-center"
                  >
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justify-center mt-6 mb-3"
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        color={
                          (new Date() - new Date(item.provider.updatedAt)) /
                            (1000 * 60) <
                          5
                            ? "primary"
                            : "warning"
                        }
                      >
                        <Avatar
                          alt={`${item.provider.firstName} ${item.provider.lastName}`}
                          src={
                            item.provider.media ? item.provider.media.url : ""
                          }
                        />
                      </StyledBadge>
                    </Stack>
                    <p className="ml-[5px]">{`${item.provider.firstName} ${item.provider.lastName}`}</p>
                  </Link>
                </div>
                <div className="flex items-center justify-between">
                  <div className="point">
                    <h3 className="text-[12px] font-medium">Points</h3>
                    <p className="text-[15px] text-[#7c7c7c] font-medium">
                      {item.rate} pts
                    </p>
                  </div>
                  <div className="status">
                    <h3 className="text-[12px] font-medium">Status</h3>
                    <p className="text-[15px] text-main font-medium">
                      Cancelled
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-2 gap-20 items-center border border-solid border-[#e9e9e9] rounded-[20px] py-[20px] px-[26px] mt-[30px] max-xl:grid-cols-1">
              <div className="flex justify-between items-center">
                <p className="mr-[-100px] text-[15px] max-w-[180px] font-medium text-second max-sm:max-w-[120px] max-sm:mr-0">
                  No Cancelled Service
                </p>
              </div>
            </div>
          )}
        </Box>
      </TabPanels>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setCurrentUser })(ReceivingServices);
