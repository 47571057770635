import React from "react";

const FAQ = () => {
  return (
    <div className="container flex items-center">
      <div className="my-12">
        <h1 className="mb-4 max-sm:text-4xl">FAQ</h1>
        <ol>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">What is the cost to use TiF?</p>
            <p className="my-2">
              There is currently no charge to use <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>.
              <br />
              Eventually we anticipate a minimal charge of $15/year.
            </p>
          </li>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">Can I cancel a service after I’ve accepted?</p>
            <p className="my-2">
              If both parties agree to mutually cancel, you may both click
              “cancel service” on the request page.
            </p>
          </li>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">
              What if I offer a service that requires spending money on gas,
              such as mowing lawns?
            </p>
            <p className="my-2">
              In this scenario you could either require more points for the
              service to compensate for what you spend on gas or require the
              service receiver to reimburse you for gas. Any financial
              reimbursement would need to be arranged and conducted outside the
              <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> platform.
            </p>
          </li>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">Can I cancel a service request?</p>
            <p className="my-2">
              If the service provider has not yet accepted your request, you may
              cancel anytime by clicking “cancel service” on the request page.
              <br />
              If the service provider has already accepted your request, in
              order to cancel, both parties must click “cancel service” on the
              request page.
            </p>
          </li>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">Can I trade goods/products on <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-3 text-[#1cc400]">TM</sup>?</p>
            <p className="my-2">
              <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> is currently only for trading services.
            </p>
          </li>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">How long do I have to use my points?</p>
            <p className="my-2">
              Points themselves do not expire but <span className="text-[#1cc400]">TRADE</span><span>IT</span><span className="text-[#1cc400]">FWD</span><sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> users must complete
              at least one service every six months (either giving or receiving)
              in order to keep their account active. If you do not complete one
              service every six months, your account will be suspended and your
              profile will not appear on the site.
            </p>
          </li>
          <li className="my-4">
            <p className="my-2 text-xl font-semibold">
              Is there a way to get points without providing a service?
            </p>
            <p className="my-2">
              Yes! If you bring in a friend, you each receive 5 bonus points.
              For each month that you request and receive at least one service,
              you receive 5 bonus points. For each month that you accept and
              complete at least one service request, you receive 5 bonus points.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default FAQ;
