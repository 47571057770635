import { useEffect, useState } from "react";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as pdfjsLib from "pdfjs-dist";
// Import the worker correctly to avoid the message "Warning: Setting up fake worker"
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import { getFileLink } from "../../util/s3";
import { Document, Page } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import axiosClient from "api";
import { toast } from "react-toastify";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { useRef } from "react";

const PortfolioCard = ({ item, serviceId, setter }) => {
  const inputRef = useRef(null);

  const [fileUrl, setFileUrl] = useState(null);
  const [, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileTitle, setFileTitle] = useState("");

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  useEffect(() => {
    setFileTitle(item.desc);
    const initFunc = async () => {
      let tmp = await getFileLink(item.media.url);
      setFileUrl(tmp);
    };
    initFunc();
  }, [item]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const removeItem = () => {
    axiosClient
      .post("/api/users/remove-portfolio", {
        serviceId,
        mediaId: item.media.id,
      })
      .then((res) => {
        setter(res.data.data.items);

        toast.success("Work Sample Is Removed.", {
          position: "top-center",
          containerId: "main",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Removing Work Sample Is Failed.", {
          position: "top-center",
          containerId: "main",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const updateDescHandler = () => {
    axiosClient
      .post("/api/users/update-portfolio-desc", {
        serviceId,
        mediaId: item.media.id,
        desc: fileTitle,
      })
      .then((res) => {
        toast.success("Work Sample Is Removed.", {
          position: "top-center",
          containerId: "main",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Removing Work Sample Is Failed.", {
          position: "top-center",
          containerId: "main",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <div className="image-item flex flex-col w-full border px-2 rounded-2xl mb-4">
      <figure className="relative w-full my-2">
        {fileUrl ? (
          item.media.url.endsWith(".pdf") ? (
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                height={600}
                renderTextLayer={false}
              />
            </Document>
          ) : (
            // <></>
            <img
              src={fileUrl}
              alt=""
              width="100%"
              height="268"
              className="rounded-[20px] object-cover"
            />
          )
        ) : (
          <img
            src="/images/no-image.jpg"
            alt="No File Available"
            width="100%"
            height="268"
            className="rounded-[20px] object-cover"
          />
        )}
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={{ fontSize: 20, color: "red" }}
          onClick={() => removeItem()}
          className="absolute top-4 right-4"
        />
      </figure>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          name={`title-${item.id}`}
          value={fileTitle}
          placeholder="No Description"
          onChange={(e) => setFileTitle(e.target.value)}
          className="w-full h-[50px] mb-2 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
          required
        />
        {inputRef.current && inputRef.current.value !== item.desc && (
          <FontAwesomeIcon
            className="absolute top-1/2 right-4 w-6 h-6 hover:text-[#2bef0a] transition-all duration-300 cursor-pointer"
            icon={faSave}
            onClick={updateDescHandler}
            style={{
              transform: "translateY(-50%)",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PortfolioCard;
