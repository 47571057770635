import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import jwt_decode from "jwt-decode";
import socketIO from "socket.io-client";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import store from "../store/store";
import { logoutUser } from "../actions/authAction";
import PrivateRoute from "./PrivateRoute";

import Header from "../view/component/common/Header/header";
import Footer from "../view/component/common/Footer/footer";

import AskQuestion from "../view/AskQuestion/askQuestion";
import AddService from "../view/AddService/addService";
import Credits from "../view/Credits/credits";
import Dashboard from "../view/Dashboard/dashboard";
import EditLocation from "view/EditLocation/editLocation";
import EditProfile from "../view/EditProfile/editProfile";
import EditService from "../view/EditService/editService";
import Home from "../view/Home/home";
import LoginPage from "../view/Auth/login";
import MyProfile from "../view/Profile/myProfile";
import PageNotFound from "../view/404/pagenotfound";
import RegisterPage from "../view/Auth/register";
import ResetPage from "../view/Auth/reset";
import VerifyPage from "../view/Auth/verifyEmail";
import ServicePage from "../view/Service/service";
import SingleService from "../view/SingleService/singleService";
import Task from "../view/Task/task";
import UserProfile from "../view/Profile/userProfile";
import ForgotPassword from "../view/Auth/forgotPassword";
import ChatHistory from "../view/ChatHistory/chatHistory";
import AboutUs from "../view/AboutUs/aboutUs";
import HowItWorks from "../view/HowItWorks/howItWorks";
import Helps from "../view/Helps/helps";
import FavouriteList from "../view/FavouriteList/favouriteList";
import ContactUs from "../view/ContactUs/contactUs";
import FAQ from "view/FAQ/faq";
import CookieConsent from "view/component/cookieConsent";
import TermsCondition from "view/TermsCondition/termsCondition";
import PrivacyPolicy from "view/PrivacyPolicy/privacyPolicy";

const styles = {};

styles.fill = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

styles.content = {
  top: "40px",
};

persistStore(store);
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    localStorage.clear();
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
} else {
  localStorage.clear();
  store.dispatch(logoutUser());
}

const socket = socketIO.connect(process.env.REACT_APP_API_HOST, {
  timeout: 5000,
  debug: true,
});

const router = () => {
  return (
    <Router>
      <Provider store={store}>
        <Header socket={socket} />
        <Route
          render={({ location }) => (
            <>
              <CookieConsent />
              <TransitionGroup exit={false} className="flex flex-1 flex-col justify-center">
                <CSSTransition
                  classNames="fade"
                  key={location.key}
                  timeout={1200}
                >
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    {/* <Route exact path="/mockup-users" component={MockupUsers} /> */}
                    <Route exact path="/register" component={RegisterPage} />
                    <Route exact path="/reset/:token" component={ResetPage} />
                    <Route exact path="/verify/:token" component={VerifyPage} />
                    <Route
                      exact
                      path="/user-profile/:id"
                      component={(props) => (
                        <UserProfile {...props} socket={socket} />
                      )}
                    />
                    <Route
                      exact
                      path="/single-service"
                      component={(props) => <SingleService {...props} />}
                    />
                    <Route exact path="/about-us" component={AboutUs} />
                    <Route exact path="/how-it-works" component={HowItWorks} />
                    <Route exact path="/help" component={Helps} />
                    <PrivateRoute
                      exact
                      path="/service"
                      component={ServicePage}
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={(props) => (
                        <Dashboard {...props} socket={socket} />
                      )}
                    />
                    <PrivateRoute
                      exact
                      path="/my-profile"
                      component={MyProfile}
                    />
                    <PrivateRoute
                      path="/ask-question"
                      component={(props) => (
                        <AskQuestion {...props} socket={socket} />
                      )}
                    />
                    <PrivateRoute
                      path="/task"
                      component={(props) => <Task {...props} socket={socket} />}
                    />
                    <PrivateRoute
                      exact
                      path="/edit-profile"
                      component={EditProfile}
                    />
                    <PrivateRoute exact path="/credits" component={Credits} />
                    <PrivateRoute
                      exact
                      path="/add-service"
                      component={AddService}
                    />
                    <PrivateRoute
                      exact
                      path="/edit-service"
                      component={EditService}
                    />
                    <PrivateRoute
                      exact
                      path="/edit-location"
                      component={EditLocation}
                    />
                    <PrivateRoute
                      exact
                      path="/chat-history"
                      component={(props) => (
                        <ChatHistory {...props} socket={socket} />
                      )}
                    />
                    <PrivateRoute
                      exact
                      path="/favourite-list"
                      component={FavouriteList}
                    />
                    <PrivateRoute exact path="/faq" component={FAQ} />
                    <PrivateRoute
                      exact
                      path="/contact-us"
                      component={ContactUs}
                    />
                    <PrivateRoute
                      exact
                      path="/terms-condition"
                      component={TermsCondition}
                    />
                    <PrivateRoute
                      exact
                      path="/privacy-policy"
                      component={PrivacyPolicy}
                    />
                    <Route exact path="*" component={PageNotFound} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </>
          )}
        ></Route>
        <Footer />
      </Provider>
    </Router>
  );
};
export default router;
