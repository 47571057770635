import React, { useEffect, useRef, useState } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import { toast } from "react-toastify";
import axiosClient from "../../../api";
import { useAtom } from "jotai";
import { serviceRegistration } from "store/jotai";

const defaultProps = {
  center: {
    lat: 37.409375796794684,
    lng: -122.08710396476175,
  },
  zoom: 10,
};

const ServiceLocationPage = (props) => {
  const [, setAddress] = useState("");
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [street, setStreet] = useState(null);
  const [streetNumber, setStreetNumber] = useState(null);
  const [location, setLocation] = useState(null);

  const [regTemp, setRegTemp] = useAtom(serviceRegistration);
  const [, setRegTempStatus] = useState(null);
  const [started, setStarted] = useState(true);
  useEffect(() => {
    setRegTempStatus(regTemp);
    if (started && regTemp) {
      regTemp.location && showPlace(regTemp.location.location);
      regTemp.location && setLocation(regTemp.location.location);
      
      setStarted(false);
    }
  }, [regTemp, started]);

  const mapRef = useRef(null);

  const getAddress = (latLng) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let tmp = results[0];
          setState(
            tmp.address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name
          );
          setCity(
            tmp.address_components
              .find((component) =>
                component.types.includes("administrative_area_level_2")
              )
              ?.long_name.replace(" County", "")
          );
          setStreet(
            tmp.address_components.find((component) =>
              component.types.includes("route")
            )?.long_name
          );
          setStreetNumber(
            tmp.address_components.find((component) =>
              component.types.includes("street_number")
            )?.long_name
          );
          setAddress(results[0].formatted_address);
        } else {
          setAddress("No results found");
        }
      } else {
        setAddress("Geocoder failed due to: " + status);
      }
    });
  };

  const showPlace = (latLng) => {
    mapRef.current.map.setCenter(latLng);
    mapRef.current.map.setZoom(10);
  };

  const pointerPicker = (mapProps, map, clickEvent) => {
    const tmp = {
      lat: clickEvent.latLng.lat(),
      lng: clickEvent.latLng.lng(),
    };
    setLocation(tmp);
    showPlace(tmp);
    getAddress(tmp);
  };

  const saveHandler = () => {
    const temp = {
      state,
      city,
      street,
      streetNumber,
      location,
    };
    setRegTemp({ ...regTemp, location: temp });
    axiosClient
      .post("/api/users/update-location", temp)
      .then((res) => {
        toast.success("Your location has been successfully registered.", {
          position: "top-center",
          containerId: "main",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to update your location.", {
          position: "top-center",
          containerId: "main",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleSelect = async (selected) => {
    const results = await geocodeByAddress(selected.label);
    const latLng = await getLatLng(results[0]);
    setAddress({ label: selected.label, latLng });
    setLocation(latLng);
    showPlace(latLng);
    const terms = selected.value.terms;
    const termsLength = terms.length;
    if (termsLength > 1) setState(terms[termsLength - 2].value);
    if (termsLength > 2) setCity(terms[termsLength - 3].value);
    if (termsLength > 3) setStreet(terms[termsLength - 4].value);
    if (termsLength > 4) setStreetNumber(terms[termsLength - 5].value);
    setAddress(selected.label);
  };

  return (
    <main className="flex-1 edit-location">
      <div className="container justify-center">
        <div className="edit-panel w-full max-w-[789px] bg-[#f9f9f9] py-4 rounded-[20px]">
          <h2 className="text-center">Register Location</h2>
          <p className="text-center mt-2 mb-6 text-sm">
            Please enter your city. Or to have your location mapped more
            precisely, please enter your address
            <br />
            (your address will not be shown on your profile).
          </p>
          <form>
            <h3 className="ml-2 mb-2">Enter Address</h3>
            <PlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              autocompletionRequest={{
                location,
              }}
              selectProps={{
                onChange: handleSelect,
              }}
            />
            <h3 className="mt-6 ml-2 mb-2">Or Select on Map</h3>
            <div className="h-72 relative">
              <Map
                ref={mapRef}
                google={props.google}
                className={"map"}
                zoom={4}
                initialCenter={defaultProps.center}
                onClick={pointerPicker}
              >
                {location && <Marker position={location} />}
              </Map>
            </div>
            <button
              type="button"
              onClick={saveHandler}
              className="block mx-auto py-3 px-16 mt-[25px] text-white bg-main border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#2bef0a]"
            >
              Save Location
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  libraries: ["places"],
})(ServiceLocationPage);
