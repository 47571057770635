// Import axios library
import axios from "axios";

// Server Api Url
const serverUrl = `${process.env.REACT_APP_API_HOST}`;

// Create an instance of axios with the base URL and headers
const axiosClient = axios.create({
  baseURL: serverUrl,
  headers: {
    Accept: "application/json",
  },
});

// Intercept requests and add token to header if present in local storage
axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("jwtToken");
  if (token && token !== "" && token !== "null")
    config.headers["token"] = token;
  return config;
});

// Intercept responses and redirect to login page if unauthorized
axiosClient.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const pathname = window.location.pathname;
    if (pathname !== "/single-service" && !pathname.includes("user-profile")) {
      if (error.response.status === 401) {
        localStorage.removeItem("jwtToken");
        // localStorage.removeItem("user");
        window.location = "/";
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

// Export the axios client instance
export default axiosClient;
