import { useEffect, useState } from "react";
import { connect } from "react-redux";
import aws from "aws-sdk";

import {
  removeFromFavorites,
  clearFavorites,
} from "../../actions/favListAction";
import axiosClient from "api";
import { Avatar, Badge, Card } from "@mui/material";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const FavouriteList = ({ auth, favourite, removeFromFavorites }) => {
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    console.log(auth.user, favourite.list)
    if (auth && auth.isAuthenticated) {
      const s3 = new aws.S3();

      axiosClient
        .post("/api/users/get-wishlist", {
          favourite: favourite.list,
        })
        .then(async (res) => {
          let tmp = [...res.data.data.users];
          for (let i = 0; i < tmp.length; i++) {
            if (tmp[i].media) {
              let params = {
                Bucket: "tradetribestore",
                Key: tmp[i].media.url,
              };
              tmp[i].media.url = await s3.getSignedUrlPromise(
                "getObject",
                params
              );
            }
          }
          setWishlist(tmp);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [auth, favourite.list]);

  const removeFavHandler = (id) => {
    removeFromFavorites(id);
    const filteredList = wishlist.filter((item) => item.id !== id);
    setWishlist(filteredList);
  };

  return (
    <main className="flex-1 favourite-list">
      <div className="container flex-col my-12">
        <div className="w-full mb-12">
          <h2 className="text-4xl">Favorite List</h2>
        </div>
        <div>
          {wishlist.length > 0 ? (
            <>
              {wishlist.map((item, index) => (
                <Card key={`user-card-${index}`} className="mb-4 p-4">
                  <div className="flex mb-4">
                    <Link to={`/user-profile/${item.id}`}>
                      <Avatar
                        alt="User Image"
                        src={item.media ? item.media.url : ""}
                      />
                    </Link>
                    {(new Date() - new Date(item.updatedAt)) / (1000 * 60) < 5 ? (
                      <Badge color="success" badgeContent="Live" />
                    ) : (
                      <Badge color="warning" badgeContent="Away" />
                    )}
                    <Link
                      to={`/user-profile/${item.id}`}
                      className="text-xl ml-8"
                    >
                      {item.firstName} {item.lastName}
                    </Link>
                    <div className="flex-1 flex justify-end">
                      <button
                        onClick={() => removeFavHandler(item.id)}
                        className="bg-transparent !outline-none border-none mr-2 text-2xl hover:rotate-45 transition-all duration-300 hover:text-green-700"
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </button>
                    </div>
                  </div>
                  {item.userBio ? (
                    <p className="mb-4">
                      <b>Bio: </b>
                      {item.userBio}
                    </p>
                  ) : (
                    <p>No profile.</p>
                  )}
                  <div>
                    {item.services.length > 0 ? (
                      <div className="mb-2">
                        {item.services.length > 1 ? (
                          <p>
                            <b>Services:</b>
                          </p>
                        ) : (
                          <p>
                            <b>Service:</b>
                          </p>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {item.services.length > 0 &&
                      item.services.map((cat, index1) => (
                        <ul key={`item-${index1}`} className={"list-none mb-4"}>
                          <li>
                            <p>
                              <b>Service Title:</b> {cat.title}
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>Job Type:</b> {cat.jobType}
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>Experience:</b> {cat.UserService.detail}
                            </p>
                          </li>
                          <li>
                            <p>
                              <b>Detail:</b> {cat.UserService.detail}
                            </p>
                          </li>
                        </ul>
                      ))}
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <p>No members found</p>
          )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  favourite: state.favourite,
});

export default connect(mapStateToProps, {
  removeFromFavorites,
  clearFavorites,
})(FavouriteList);
