import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import aws from "aws-sdk";
import { convertDateTime } from "../../../util/convertDateTime";
import { Link } from "react-router-dom";

const Review = ({ data }) => {
  const [avatarLink, setAvatarLink] = useState("");

  useEffect(() => {
    const s3 = new aws.S3();
    const getImageLink = async () => {
      if (data.from && data.from.media) {
        const params = {
          Bucket: "tradetribestore",
          Key: data.from.media.url,
        };
        const url = await s3.getSignedUrlPromise("getObject", params);
        setAvatarLink(url);
      }
    };
    getImageLink();
  }, [data]);

  return (
    <>
      {data && (
        <div className="review pb-[24px] mb-[33px] border-b border-x-0 border-t-0 border-solid border-[#e9e9e9]">
          <div className="flex justify-between items-center">
            <div className="user-info flex items-center">
              <Link
                to={`/user-profile/${data.from.id}`}
                className="flex items-center"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  className="justify-center z-10"
                >
                  <Avatar alt="Review Writer Avatar" src={avatarLink} />
                </Stack>
                {data.from && (
                  <p className="mx-[10px]">{`${data.from.firstName} ${data.from.lastName}`}</p>
                )}
              </Link>
              <FontAwesomeIcon
                icon={faStar}
                style={{ fontSize: 14, color: "#1CC300" }}
              />
              <p className="text-main ml-[4px] text-[15px] leading-none">
                {data.star.toFixed(1)}
              </p>
            </div>
            <div className="time flex items-center">
              <FontAwesomeIcon
                icon={faClock}
                style={{ fontSize: 20, color: "#000" }}
                className="mr-[3px]"
              />
              <p className="text-[15px]">{convertDateTime(data.createdAt)}</p>
            </div>
          </div>
          <div className="nationality flex my-[18px] items-center">
            <img
              src="/images/nation-1.png"
              alt="nation-1"
              width="23"
              height="15"
            />
            <p className="text-[15px] ml-[3px]">United States</p>
          </div>
          <p className="pr-[50px] text-[15px] text-[#989898]">{data.desc}</p>
        </div>
      )}
    </>
  );
};

export default Review;
