// Import createStore, applyMiddleware and compose from redux library
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
// Import persistor from redux-persist library
import { persistStore } from "redux-persist";
// Import thunk middleware from redux-thunk library
import thunk from "redux-thunk";
// Import rootReducer from reducers file
import rootReducer from "../reducers";

// Initialize empty state object
const initialState = {};
// Create array of middlewares
const middleware = [thunk];
// Compose enhancers with Redux DevTools Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create store with rootReducer, initialState and composed middlewares
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

// Create persistor for the store
export const persistor = persistStore(store);

export default store;