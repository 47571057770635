import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import "./login.css";
import axiosClient from "api/index.js";

const VerifyEmail = () => {
  const { token } = useParams();
  const [showState, setShowState] = useState(false);
  const [verified, setVerified] = useState(false);
  const auth = useSelector((state) => state.auth);

  let history = useHistory();

  useEffect(() => {
    axiosClient
      .post("/public/verify-email-token", { token })
      .then((res) => {
        setShowState(true);
        setVerified(true);
      })
      .catch((error) => {
        setShowState(true);
        console.error(error);
      });
  }, [token]);

  useEffect(() => {
    if (verified) {
      setTimeout(() => {
        history.push("/login?verify=true");
      }, 3000);
    } else {
      setTimeout(() => {
        history.push("/");
      }, 3000);
    }
  }, [verified, history]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      auth.status && history.push("/dashboard");
      !auth.status && history.push("/service");
    }
  }, [auth, history]);

  return (
    <div className="login-form-container">
      <form className="forgot-form">
        <div className="px-12">
          <p className="login-text-login">Email verification</p>

          {showState ? (
            <>
              {verified ? (
                <p className="py-[20px] text-center">
                  <span className="text-main">
                    Congratulations! Your account is verified.
                  </span>
                  <br />
                  Redirecting...
                </p>
              ) : (
                <p className="py-[20px] text-center">
                  <span className="text-red-600">
                    Invalid link! Please try with correct link.
                  </span>
                  <br />
                  Redirecting...
                </p>
              )}
            </>
          ) : (
            <p>Waiting...</p>
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(VerifyEmail);
