import React from "react";

const HowItWorks = () => {
  return (
    <div className="container flex items-center">
      <div>
        <h1 className="my-8 max-sm:px-0 max-2xl:px-4 max-sm:text-5xl">How It Works</h1>
        <ol>
          <li className="my-4">
            Simply create a profile and offer up to three services; make sure
            the heading/name of your service is specific but not too wordy. A
            guideline to keep in mind when naming your service is that other
            users will find you by searching with keywords, so make yourself
            easy to find!
            <br />
            For example: Math Tutoring, Swimming Lessons, Deep Tissue Massage
          </li>
          <li className="my-4">
            In the field below the heading for each service you are offering,
            describe your experience, history and the specifics of the service
            you are providing. The more detailed you can be, the better. If the
            service you are providing requires a license, certification, etc.,
            there will be an option to upload those documents (documents will be
            visible to other users). Even if your service does not require a
            license or certification, it’s a good idea to upload any kind of
            evidence that highlights your experience and skills.{" "}
            <span className="text-[#1cc400]">TRADE</span>
            <span>IT</span>
            <span className="text-[#1cc400]">FWD</span>
            <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>&nbsp;
            users are expected to be at a professional level of any service they
            are offering, if applicable.
          </li>
          <li className="my-4">
            Users may also offer to trade general services that don’t require
            specialized skills, such as running errands, moving boxes, cleaning
            out someone’s garage, etc. For services such as these you would
            enter “General Services” as your heading and then describe in detail
            the tasks you are open to performing.
          </li>
          <li className="my-4">
            Once a service provider accepts a request for service from a service
            receiver, the points needed for this service will leave the
            receiver’s account. Once the service is confirmed completed by both
            the giver and receiver, the points will land in the giver’s account.
          </li>
        </ol>
        <h3 className="mt-8 max-sm:px-0 max-2xl:px-4 mb-8">
          Guidance for Assigning a Point Value to your Service
        </h3>
        <p className="pl-10 mb-3">
          The <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> platform
          currently runs on a system that allows users to ask for 5, 10, 15 or
          20 points for their service. This is to allow practitioners with
          different levels of experience to trade fairly.
        </p>
        <p className="pl-10 mb-3">
          For example, let's say you're a newly certified/licensed massage
          therapist with little to no actual work experience. In that case you
          might want to consider offering a one-hour session for 5 points. Or
          maybe you're a seasoned massage therapist with years of experience, in
          that scenario you might ask 20 points for a thirty-minute session. Are
          you somewhere in between? Maybe 10 points for 75 minutes!
        </p>
        <p className="pl-10 mb-3">
          Ultimately you can assign any of the available point values you want
          and decide for yourself the length of your session (if your service is
          time-based), but generally speaking, the more skill and/or experience
          you have, the higher the point value. This is not much different than
          the normal economy we live in today, but with{" "}
          <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup>, it's all
          trade-based!
        </p>
        <p className="pl-10 mb-3">
          Also, the beauty of <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> is that
          even if you are only asking 5 points for your service, a 20-point
          service is still available for you to receive once you've shared your
          service four times.
        </p>
        <p className="pl-10 mb-20">
          It is our expectation that the needs and habits of our users will
          determine how <span className="text-[#1cc400]">TRADE</span>
          <span>IT</span>
          <span className="text-[#1cc400]">FWD</span>
          <sup className="text-[10px] -top-2 text-[#1cc400]">TM</sup> and our
          point system evolves over time. YOU are our pioneers, and we're so
          glad to have you along for the ride!
        </p>
      </div>
    </div>
  );
};

export default HowItWorks;
