import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import "./login.css";
import { toast } from "react-toastify";
import axiosClient from "api/index.js";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const auth = useSelector((state) => state.auth);

  let history = useHistory();

  useEffect(() => {
    if (auth.isAuthenticated) {
      auth.status && history.push("/dashboard");
      !auth.status && history.push("/service");
    }
  }, [auth, history]);

  const requestPasswordReset = () => {
    const userData = {
      email: email,
    };
    axiosClient
      .post("/public/request-reset-password", { userData })
      .then((res) => {
        if (res.statusText === "OK") {
          toast.success(
            "Password reset requested successfully. Please check your mailbox. (If not found, please check spam.)",
            {
              position: "top-center",
              containerId: "main",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        } else {
          toast.error(
            "Failed to send request. Please check your email address.",
            {
              position: "top-center",
              containerId: "main",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Failed to send request. Please check your email address.",
          {
            position: "top-center",
            containerId: "main",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  return (
    <div className="login-form-container">
      <form className="forgot-form py-10">
        <div className="px-12">
          <p className="login-text-login">Reset Password</p>
          <p className="py-[20px] text-center">
            Enter your email to send verification link.
          </p>
          <div className="form-group mt-2">
            <input
              type="email"
                className="form-control !py-2.5 rounded-xl "
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group mt-4 btn-center pb-4 border-0">
            <button
              type="button"
              className="btn btn-success btn-forgot-request px-4 py-3 text-white"
              onClick={requestPasswordReset}
            >
              Request Reset Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(ForgotPassword);
