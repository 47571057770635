import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import "./login.css";
import { toast } from "react-toastify";
import axiosClient from "api";

const ResetPage = () => {
  const [password, setPassword] = useState("");
  const { token } = useParams();

  const auth = useSelector((state) => state.auth);

  let history = useHistory();

  useEffect(() => {
    if (auth.isAuthenticated) {
      auth.status && history.push("/dashboard");
      !auth.status && history.push("/service");
    }
  }, [auth, history]);

  const resetPassword = () => {
    if (token && token !== "") {
      const userData = {
        password: password,
        token,
      };

      axiosClient
        .post("/public/reset-password", { userData })
        .then((res) => {
          toast.success("Password changed successfully!", {
            position: "top-center",
            containerId: "main",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          history.push("/login");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Invalid Action.", {
            position: "top-center",
            containerId: "main",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  return (
    <div className="login-form-container">
      <form className="reset-form">
        <div className="login-form-content">
          <p className="login-text-login">Reset Password</p>
          <p className="login-text py-[20px]">Enter your new password here.</p>

          <div className="form-group mt-2">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              autoComplete="true"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group mt-4 btn-center pb-[40px] border-0">
            <button
              type="button"
              className="btn btn-success btn-login"
              onClick={resetPassword}
            >
              Reset Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(ResetPage);
