import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import aws from "aws-sdk";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import api from "../../api";

import ServiceDetailList from "./component/serviceDetailList";
import { toastify, toastType } from "util/toastify";
import { Avatar, Checkbox, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { uploadFile } from "util/s3";
import { connect } from "react-redux";

import { serviceUpdatePhoto } from "../../actions/serviceAction";
import { setAvatar } from "../../actions/authAction";
import { useMemo } from "react";

function hasChildNodes(className) {
  // Find the element with the specified class name
  var element = document.querySelector("." + className);

  // Check if the element exists and has child nodes
  if (element && element.hasChildNodes()) {
    return true;
  }

  return false;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const EditProfile = ({ serviceUpdatePhoto, setAvatar }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [user, setUser] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [notificationState, setNotificationState] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [passwordSet, setPasswordSet] = useState({
    currPwd: "admin",
    newPwd: "admin",
    confPwd: "admin",
  });
  const [photoName, setPhotoName] = useState("");
  const [localLink, setLocalLink] = useState("");

  const hiddenFileInput = useRef(null);

  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  const s3 = useMemo(() => new aws.S3(), []);

  useEffect(() => {
    let isMounted = true;

    api
      .get("/api/me")
      .then(async (res) => {
        if (isMounted) {
          let tmp = { ...res.data.data.user };
          if (tmp.media) {
            let params = {
              Bucket: "tradetribestore",
              Key: tmp.media.url,
            };
            tmp.media.url = await s3.getSignedUrlPromise("getObject", params);
          }
          setNotificationState(tmp.notification ? true : false);
          setUser(tmp);
          if (urlParams.get("regist") === "true") {
            document.querySelector("#scrollable-auto-tab-1").click();
            setTimeout(() => {
              document
                .querySelector("#portfolio-list")
                .scrollIntoView({ behavior: "smooth" });
              if (!hasChildNodes("Toastify")) {
                toastify(
                  "Please add your work samples and certifications.",
                  toastType.success
                );
              }
            }, 100);
          }
        }
      })
      .catch((err) => {
        if (isMounted) console.error(err);
      });

    return () => {
      isMounted = false;
    };
  }, [s3, urlParams]);

  useEffect(() => {
    if (photoName !== "") {
      setShowSpinner(true);
      serviceUpdatePhoto(photoName);
      setTimeout(() => setShowSpinner(false), 1000);
    }
  }, [photoName, serviceUpdatePhoto]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFileChange = async (e) => {
    const fileUpload = e.target.files[0];
    setShowSpinner(true);
    await uploadFile(fileUpload, setPhotoName, setUploadPercent);
    setLocalLink(URL.createObjectURL(fileUpload));
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const changeValue = (value, field) => {
    let tmp = user;
    tmp[field] = value;
    setUser(tmp);
  };

  const saveHandler = () => {
    api
      .post("/api/users/update", user)
      .then((_res) => {
        toastify("Your Profile Updated Successfully.", toastType.success);
      })
      .catch((err) => {
        console.error(err);
        toastify("Your Profile Update Is Failed.", toastType.error);
      });
  };

  const updatePasswordSet = (value, field) => {
    let tmp = passwordSet;
    tmp[field] = value;
    setPasswordSet(tmp);
  };

  const changePasswordHandler = () => {
    if (passwordSet.newPwd === passwordSet.confPwd) {
      api
        .post("/api/changePassword", passwordSet)
        .then((_res) => {
          toastify("Your Password Updated Successfully.", toastType.success);
        })
        .catch((err) => {
          console.error(err);
          toastify("Updating Password Failed.", toastType.error);
        });
    } else {
      toastify("Password is mismatched.", toastType.error);
    }
  };

  return (
    <main className="flex-1 edit-profile my-12">
      <div className="container !max-w-[915px]">
        {user && (
          <div className={classes.root}>
            <AppBar
              className="rounded-[15px] overflow-hidden max-w-[800px] mx-auto"
              position="static"
              color="default"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="transparent"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                sx={{
                  "& button": { borderRadius: 5 },
                  "& button:hover": { backgroundColor: "green" },
                  "& button:focus": {
                    backgroundColor: "green",
                    borderBottom: "none",
                  },
                  "& button:active": {
                    backgroundColor: "green",
                    borderBottom: "none",
                  },
                }}
                className="text-[#000]"
              >
                <Tab
                  label="Personal Details"
                  className="w-1/3"
                  {...a11yProps(0)}
                />
                <Tab label="Services" className="w-1/3" {...a11yProps(1)} />
                <Tab label="Password" className="w-1/3" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className="pt-20">
              <h2>Personal Details</h2>
              <p className="mt-[12px] mb-[34px] text-[#a1a1a1]">
                Update your personal information
              </p>
              <div className="relative inline-block">
                <Avatar
                  alt="Uploaded Avatar"
                  src={
                    localLink !== ""
                      ? localLink
                      : user.media
                      ? user.media.url
                      : ""
                  }
                  sx={{ width: 150, height: 150 }}
                />
                {showSpinner && uploadPercent < 100 && (
                  <div className="absolute flex justify-center items-center w-full h-full z-10 top-0 left-0 bg-slate-400 opacity-50 rounded-full">
                    <CircularProgress className="z-20" />
                  </div>
                )}
                {showSpinner && uploadPercent < 100 && (
                  <div className="absolute flex justify-center items-center w-full h-full z-10 top-0 left-0 rounded-full">
                    <span className="text-white">{uploadPercent}%</span>
                  </div>
                )}
                <div className="absolute right-0 bottom-0 bg-[#1cc300] hover:bg-[#1ca300] transition-all duration-300 w-10 h-10 rounded-full flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={faPencil}
                    style={{ fontSize: 23, color: "#000" }}
                    className="text-white"
                    onClick={handleClick}
                  />
                  <input
                    type="file"
                    className="form-control mt-1"
                    ref={hiddenFileInput}
                    onChange={(e) => handleFileChange(e)}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <form className="pt-4">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  defaultValue={user.firstName}
                  onChange={(e) => changeValue(e.target.value, "firstName")}
                  className="w-full max-w-[511px] h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  defaultValue={user.lastName}
                  onChange={(e) => changeValue(e.target.value, "lastName")}
                  className="w-full max-w-[511px] h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="your@email.com"
                  defaultValue={user.email}
                  onChange={(e) => changeValue(e.target.value, "email")}
                  className="w-full max-w-[511px] h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <h3 className="mb-3 ml-2">About you</h3>
                <textarea
                  type="text"
                  name="field"
                  defaultValue={user.userBio}
                  onChange={(e) => changeValue(e.target.value, "userBio")}
                  placeholder="Lorem ipsum dolor sit amet consectetur. A rhoncus mi eu risus. Aliquam eget ullamcorper ultrices id mus cras quisque. Enim lectus pretium fringilla lorem consectetur risus. eu risus. Aliquam eget ullamcorper ultrices id mus cras quisque. Enim lectus pretium fringilla lorem consectetur risus."
                  className="w-full max-w-[511px] h-[194px] mb-4 pl-4 py-2 pr-2 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <div className="pb-8">
                  <Checkbox
                    className="mr-1"
                    checked={notificationState}
                    onClick={(e) => {
                      setNotificationState(e.target.checked);
                      changeValue(e.target.checked, "notification");
                    }}
                  />
                  Enable Email Push Notifications for Inquiries and Service
                  Requests.
                </div>
                <button
                  type="button"
                  onClick={() => saveHandler()}
                  className="block py-2 px-16 mt-[15px] mb-[60px] text-white bg-main border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#2bef0a]"
                >
                  Save
                </button>
              </form>
            </TabPanel>
            <TabPanel value={value} index={1} className="pt-20">
              <h2 className="text-center mb-4">Services</h2>
              {user && <ServiceDetailList user={user} />}
            </TabPanel>
            <TabPanel value={value} index={2} className="pt-20">
              <h2>Change Password</h2>
              <p className="mt-[12px] mb-[34px] text-[#a1a1a1]">
                Update your password after confirmation
              </p>
              <form>
                <input
                  type="password"
                  name="current_password"
                  placeholder="Current Password"
                  onChange={(e) => updatePasswordSet(e.target.value, "currPwd")}
                  className="w-full max-w-[511px] h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <input
                  type="password"
                  name="new_password"
                  placeholder="New Password"
                  onChange={(e) => updatePasswordSet(e.target.value, "newPwd")}
                  className="w-full max-w-[511px] h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <input
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm New Password"
                  onChange={(e) => updatePasswordSet(e.target.value, "confPwd")}
                  className="w-full max-w-[511px] h-[50px] mb-4 pl-4 border border-solid border-[#e9e9e9] outline-none rounded-[13px]"
                  required
                />
                <button
                  type="button"
                  onClick={() => changePasswordHandler()}
                  className="block py-2 px-16 mt-[15px] mb-[20px] text-white bg-main border-none outline-none rounded-full transition-colors duration-300 hover:bg-[#2bef0a]"
                >
                  Save
                </button>
              </form>
            </TabPanel>
          </div>
        )}
      </div>
    </main>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  service: state.service,
});

export default connect(mapStateToProps, { serviceUpdatePhoto, setAvatar })(
  EditProfile
);
