// Importing React, Route, Redirect, connect and PropTypes from the respective libraries
import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// PrivateRoute component to check if user is authenticated or not
const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      // If user is authenticated, render the component passed in props
      auth.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Component {...props} />
        // Else redirect to '/'
        // <Redirect to="/" />
      )
    }
  />
);

// Setting propTypes for PrivateRoute component
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

// Exporting the connected version of PrivateRoute component
export default connect(state => ({ auth: state.auth }))(PrivateRoute);