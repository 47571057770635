import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(date, task_id, family_member, counts) {
  return { date, task_id, family_member, counts };
}

const rows = [
  createData('12 jan 2022', '#65JHJLKL343J', 'Leo Septimus', 12),
  createData('12 jan 2022', '#65JHJLKL343J', 'Marilyn Culhane', 56),
  createData('12 jan 2022', '#65JHJLKL343J', 'Giana Levin', 43),
  createData('12 jan 2022', '#65JHJLKL343J', 'Paityn Geidt', 43),
  createData('12 jan 2022', '#65JHJLKL343J', 'Anika Botosh', 7),
  createData('12 jan 2022', '#65JHJLKL343J', 'Charlie Lipshutz', 11),
  createData('12 jan 2022', '#65JHJLKL343J', 'Roger Geidt', 10),
  createData('12 jan 2022', '#65JHJLKL343J', 'Madelyn Ekstrom Bothman', 43),
];

export default function PointsTable() {
    return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="left">Task ID</StyledTableCell>
                <StyledTableCell align="left">Family Member</StyledTableCell>
                <StyledTableCell align="left">Given/Revieved</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.date}>
                  <StyledTableCell component="th" scope="row">
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell align="left"><Link to="/">{row.task_id}</Link></StyledTableCell>
                  <StyledTableCell align="left"><Link to="/">{row.family_member}</Link></StyledTableCell>
                  <StyledTableCell align="left">{row.counts}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
}