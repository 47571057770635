import React, { useEffect, useState } from "react";
import { serviceStore } from "../../../actions/serviceAction";
import { connect, useSelector } from "react-redux";

const ServiceNamePage = ({ serviceStore }) => {
  const service = useSelector((state) => state.service);
  const [serviceName, setServiceName] = useState(
    service.serviceData["serviceName"]
  );

  useEffect(() => {
    serviceStore({
      num: 1,
      name: serviceName,
      flag: false,
    });
  }, [serviceName, serviceStore]);

  return (
    <div className="service-name">
      <p className="service-text-service mb-2 leading-none">
        What kind of service are you offering?
      </p>
      <p className="service-text">
        Be brief but specific. You will be able to give{" "}
        <br className="max-sm:hidden" />
        more details in the next step.
      </p>
      <div className="form-group mt-3 mb-20">
        <span className="service-text-label">Name of Service</span>
        <input
          type="text"
          className="form-control !py-4 mt-1 mb-3 border-none rounded-[13px]"
          placeholder="Ex: Yoga"
          defaultValue={serviceName}
          onChange={(e) => setServiceName(e.target.value)}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  service: state.service,
});
export default connect(mapStateToProps, { serviceStore })(ServiceNamePage);
