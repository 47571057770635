// Importing combineReducers from redux library
import { combineReducers } from "redux";

// Importing authReducer, serviceReducer and favListReducer from respective files
import authReducer from "./authReducer";
import serviceReducer from "./serviceReducer";
import favListReducer from "./favListReducer";
import notificationReducer from "./notificationReducer";
import searchReducer from "./searchReducer";

// Combining all the reducers into one rootReducer
const rootReducer = combineReducers({
  auth: authReducer,
  service: serviceReducer,
  favourite: favListReducer,
  notification: notificationReducer,
  search: searchReducer
});

// Exporting rootReducer
export default rootReducer