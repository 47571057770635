import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as faHeartSolid,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

import Icon from "../../assets/images/icon.png";
import {
  addToFavorites,
  removeFromFavorites,
} from "../../actions/favListAction";
import axiosClient from "../../api";
import { toastify, toastType } from "../../util/toastify";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // backgroundColor: "#1CC400",
    // color: "#1CC400",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const BasicCard = ({
  auth,
  favourite,
  data,
  addToFavorites,
  removeFromFavorites,
}) => {
  const history = useHistory();
  const location = useLocation();

  const [showState] = useState(location.pathname.includes("single-service"));
  const [loading, setLoading] = useState(false);
  const [favouriteState, setFavouriteState] = useState(false);

  useEffect(() => {
    if (favourite.list && favourite.list.includes(data.id)) {
      setFavouriteState(true);
    }
  }, [favourite, data]);

  const askQuestionHandler = (id) => {
    if (auth.isAuthenticated) {
      if (auth.user.userId === parseInt(id)) {
        toastify("It's you. Try with others.", toastType.warn);
      } else {
        axiosClient
          .get(`/api/chat/create-aq/${data.id}`)
          .then((res) => {
            history.push(`/ask-question?roomId=${res.data.data.roomId}`);
          })
          .catch((err) => {
            console.error(err);
            toastify("Internal Server Error(s)", toastType.error);
          });
      }
    } else {
      toastify("Please sign in first.", toastType.warn);
    }
  };

  const toggleFavouriteHandler = (id) => {
    if (auth.user.userId === parseInt(id)) {
      toastify("It's you. Try with others.", toastType.warn);
      return;
    }
    if (auth.isAuthenticated) {
      setLoading(true);

      let timer = setTimeout(() => {
        setLoading(false);
        if (favouriteState) {
          removeFromFavorites(id);
        } else {
          addToFavorites(id);
        }
        setFavouriteState(!favouriteState);
        return () => clearTimeout(timer);
      }, 1000);
      return;
    } else {
      toastify("Please sign in first.", toastType.warn);
      return;
    }
  };

  return (
    <Link to={`/user-profile/${data.id}`}>
      <Card
        sx={{ minWidth: 275 }}
        className="text-center !bg-[#F9F9F9] !shadow-none !rounded-[20px] transition-shadow duration-300 hover:!drop-shadow-md"
      >
        <CardContent className="text-center pb-4 relative">
          <Stack
            direction="row"
            spacing={2}
            className="justify-center mt-6 mb-2"
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color={
                (new Date() - new Date(data.updatedAt)) / (1000 * 60) < 5
                  ? "primary"
                  : "warning"
              }
            >
              <Avatar
                alt={`${data.firstName} ${data.lastName}`}
                src={data.media ? data.media.url : ""}
                className="min-w-[90px] min-h-[90px]"
              />
            </StyledBadge>
          </Stack>
          <Typography variant="body2" className="!text-base">
            {`${data.firstName} ${data.lastName}`}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {data.receivedReviews.length > 0 ? (
              <>
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ fontSize: 16, color: "#1CC300", zIndex: 10 }}
                />
                {data.avgStars.toFixed(1)} ({data.reviewCount} reviews)
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ fontSize: 16, color: "#1CC300", zIndex: 10 }}
                />
                0.0 (No reviews)
              </>
            )}
          </Typography>
          <Typography className="absolute top-[10px] left-4 cursor-pointer">
            {showState && (
              <FontAwesomeIcon
                onClick={() => toggleFavouriteHandler(data.id)}
                icon={favouriteState ? faHeartSolid : faHeart}
                flip={loading}
                style={{ fontSize: 18, color: "#CECECE", zIndex: 10 }}
              />
            )}
          </Typography>
          <Typography
            sx={{ fontSize: 11 }}
            className="absolute inline-flex items-center gap-1 top-[10px] right-4"
          >
            <img src={Icon} alt="icon" width="20px" height="18px" />
            {data.avgRate ? data.avgRate : 0} per task
          </Typography>
        </CardContent>
        <CardActions className="justify-center mb-4">
          {data.services.length > 0 ? (
            data.services.map((service, index) => (
              <Button
                key={`user-service-${index}`}
                size="small"
                className="py-1 px-3 !bg-[#F0F0F0] !rounded-full !text-[13px] !text-[#000]"
              >
                {service.title} {service.type && <>({service.type})</>}
              </Button>
            ))
          ) : (
            <></>
          )}
        </CardActions>
        {showState && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              askQuestionHandler(data.id);
            }}
            className="max-w-[190px] mx-auto text-white py-2 px-8 mb-8 !bg-second !border-second capitalize rounded-full transition-all duration-300 hover:!bg-main hover:!border-main"
          >
            Learn More
          </button>
        )}
      </Card>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  favourite: state.favourite,
});

export default connect(mapStateToProps, {
  addToFavorites,
  removeFromFavorites,
})(BasicCard);
