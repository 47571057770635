// import necessary modules
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

// set environment variables
const S3_BUCKET = "tradetribestore";
const REGION = "us-west-1";

AWS.config.update({
  accessKeyId: "AKIA6GBMBGTVARFGTCSS",
  secretAccessKey: "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",
});

// create a new instance of S3
export const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

// upload file to S3 bucket and set photo name and upload percent
export const uploadFile = async (file, setPhotoName, setUploadPercent) => {
  // get file type
  const fileType = file.name.split(".").pop();
  // generate unique file name
  const fileName = uuidv4() + "." + fileType;

  // set photo name
  setPhotoName(fileName);

  // set parameters for putObject
  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key: fileName,
  };

  // upload file to S3 bucket
  await myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      // set upload percent
      setUploadPercent(Math.round((evt.loaded / evt.total) * 100));
    })
    .send((err, _data) => {
      if (err) console.error(err);
    });
};

// upload file to S3 bucket and return file name
export const uploadFileSecond = async (data) => {
  // get file type
  const fileType = data.file.name.split(".").pop();
  // generate unique file name
  const fileName = uuidv4() + "." + fileType;

  // set parameters for putObject
  const params = {
    ACL: "public-read",
    Body: data.file,
    Bucket: S3_BUCKET,
    Key: fileName,
  };

  // upload file to S3 bucket
  await myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      console.info(Math.round((evt.loaded / evt.total) * 100));
    })
    .send((err, _data) => {
      if (err) console.error(err);
    });

  // return file name
  return fileName;
};

// get link of file in S3 bucket using file name
export const getFileLink = async (filename) => {
  const s3 = new AWS.S3();
  let params = {
    Bucket: "tradetribestore",
    Key: filename,
  };
  let url = await s3.getSignedUrlPromise("getObject", params);

  return url;
};
